import React, { useState, useEffect } from "react";
import styled, { ThemeConsumer } from "styled-components";
import Icon from "../../../../components/Icon";
import { theme } from "../../../../design/Themes";
import { isMobile, isMobileOnly } from "react-device-detect";
import { withLocalize, Translate } from "react-localize-redux";
import isEmpty from "lodash/isEmpty";

import DeletingRecordLoader from "../../../../components/Loaders/DeletingRecordLoader";
import Container from "../../../../components/Containers/Container";
import InputField from "../../../../components/InputField";
import BlockRadioboxNew from "../../../../components/BlockRadioboxNew";
import Checkbox from "../../../../components/Checkbox";
import SelectBox from "../../../../components/SelectBox";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import LinkButton from "../../../../components/Buttons/LinkButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import PhoneNumberInput from "../../../../components/PhoneNumberInput/PhoneNumberInput";
import { transliterateToCyrilic } from "../../../../services/transliterateService";
import iconPersonActive from "../../../../assets/images/icon-person-active.svg";
import iconPersonInactive from "../../../../assets/images/icon-person-inactive.svg";
import iconBusinessActive from "../../../../assets/images/icon-business-active.svg";
import iconBusinessInactive from "../../../../assets/images/icon-business-inactive.svg";
import iconUpload from "../../../../assets/images/icon-upload-active.svg";
import DataPreviewModal from "../../DataPreviewModal";

import { getListDomainTemplates, createNewDomainTemplate, fetchDataFromUJP, assignTemplateToDomain, fetchCustomerPersonalInformation, uploadDocumentSignedV2, getDomainTemplateById, checkIfTemplateExists } from '../../../../services/customerService'

import {
  CONTACT_TYPE_ADMINISTRATIVE,
  CONTACT_TYPE_REGISTRANT,
  CONTACT_TYPE_TECHNICAL
} from "../../../Domains/ViewDomain/DomainContacts/DomainContactTypes";

import {
  CART_DOMAIN_TYPE_REGISTER,
  CART_DOMAIN_TYPE_TRANSFER,
} from "../../CartDomainTypes/CartDomainTypes";
import Modal from "../../../../components/Modal";
import { Field, Form } from "react-final-form";
import { CONTACT_DETAILS_TYPE_COMPANY, CONTACT_DETAILS_TYPE_INDIVIDUAL } from "../../../Domains/ViewDomain/DomainContacts/ContactDetailsForm/ContactDetailsTypes";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import CustomTooltip from "../../../../components/CustomTooltip";
import Radiobox from "../../../../components/Radiobox";
import { normalizeMacedonianPhoneNumber, validateCountry, validateEmail, validateFirstName, validateLastName, validateMkPhoneNumber } from "../../../../common/validationRules";
import { getCartId } from "../../../../services/cartService";
import { withRouter } from "react-router-dom";
import { ROUTE_CART_CHECKOUT, ROUTE_SHOP_DOMAINS } from "../../../../routes/routes";
import DomainRequirementsModal from "../../DomainRequirementsModal";
import en from "../../../../translations/en.json";
import mk from "../../../../translations/mk.json";
import { getApplicationLocale } from "../../../../services/localizationService";
import FileUploadButton from "../../../../components/FileUploadButton/FileUploadButton";
import MissingTekovnaModal from "../../../../components/MissingTekovnaModal/MissingTekovnaModal";
import DnsSettingModal from "../../../../components/DnsSettingModal/DnsSettingModal";

//Lang
const lang = {
  en: en,
  mk: mk
};

const resolvePath = (object, path, defaultValue) =>
  path.split(".").reduce((o, p) => (o ? o[p] : defaultValue), object);

const translate = key =>
  resolvePath(lang[getApplicationLocale()], key, undefined);


// Styled Components

const StyledWrapper = styled.div`
  padding: ${isMobileOnly ? '10px 15px' : '30px 50px;'}
  ${isMobileOnly ? 'text-align: center' : ''}
  ${isMobileOnly ? 'display: flex' : ''}
  ${isMobileOnly ? 'flex-direction: column' : ''}
  ${isMobileOnly ? 'align-items: center' : ''}
`;

const StyledDescription = styled.div`
  font-size: ${isMobileOnly ? '12px' : '14px;'}
  color: ${theme.neutral6};
  margin-bottom: ${isMobileOnly ? '10px' : '20px'};
`;

const StyledDescriptionTemplate = styled.div`
  font-size: ${isMobileOnly ? '12px' : '14px;'}
  ${isMobileOnly ? 'text-align: start' : ';'}
  color: ${theme.neutral4};
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledBorder = styled.div`
  border: 1px solid ${theme.neutral3}
`;

const StyledText = styled.div`
  font-size: ${isMobileOnly ? '16px' : '23px;'}
  color: ${theme.neutralBase};
  line-height: 1.2;
  display: flex;
  align-items: center;
`;

const StyledErrorText = styled.div`
  font-size: 14px;
  color: ${theme.red2};
  display: flex;
  align-items: center;
`;

const StyledSmallText = styled.div`
  font-size: 14px;
  color: ${theme.yellowBase};
  line-height: 1.2;
  display: flex;
  font-weight: 400;
  align-items: center;
`;

const StyledSection = styled.div`
  border-radius: 8px;
`;

const StyledCheckbox = styled.div`
  margin-top: 40px;

  /* Ensure that the input shows pointer cursor */
  input {
    ${isMobileOnly ? ' ' : 'margin-right: 10px;'}
    cursor: pointer;
  }

  a { flex-direction:
    color: ${theme.neutral6};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

// Added cursor:pointer to the container in case it helps the clickable area.
const CheckboxInline = styled.div`
  ${isMobileOnly ? 'padding: 10px;' : 'padding: 20px 10px 10px 50px;'}
  cursor: pointer;
  .custom-checkbox {
    width: 100% !important;
  }
  .input-help-block {
    position: relative !important;
    top: 0;
  }
`;

const StyledDomain = styled.div`
  padding: ${props => (isMobileOnly ? "20px" : "50px")};
`;

const StyledDomainSection = styled.div`
  margin-top: 20px;
`;

const StyledFooter = styled.div`
  gap: ${isMobileOnly ? '10px' : '20px'}
  padding-bottom: 30px;
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
`;

const StyledTypeTitle = styled.h4`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${theme.neutral4};
  margin-bottom: 5px;
  text-transform: uppercase;
`;

const StyledDomainName = styled.h4`
  font-weight: 600;
  font-size: 1.4;
  color: ${theme.neutralBase};
  word-break: break-word;
`;

const StyledDeleteIcon = styled.span`
  position: absolute;
  right: 0;
  top: ${props => (props.isMobile ? "-10px" : "-20px")};
  cursor: ${props => (props.isDeleting ? "default" : "pointer")};
`;


const TabContainer = styled.div`
  margin-top: 20px;
`;

const TabButtons = styled.div`
  display: flex;
  gap: ${isMobileOnly ? '5px' : '15px'}
`;

const TabButton = styled.button`
  background-color: ${props => (props.active ? theme.blue5 : theme.neutral2)};
  border: none;
  padding: ${isMobileOnly ? '' : '10px 25px;'}
  border-radius: 4px 4px 0 0;
  font-size: 14px;
  font-weight: bold;
  color: ${theme.white};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${props => (props.disabled ? theme.neutral2 : theme.blue5)};
  }
`;

const TabContent = styled.div`
  font-size: 14px;
  color: ${theme.neutral6};
`;

const StyledContactTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  line-height: 1.43;
  color: ${theme.neutralBase};
  text-transform: uppercase;
  float: left;
`;

const StyledContactHeader = styled.div`
  margin-bottom: 20px;
`;
const StyledCustomTooltip = styled.div`
  display: inline;
  margin-bottom: 5px
`;

const StyledCyrillic = styled.div`
  min-width: 250px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 0;
`;


const StyledHelp = styled.div`
  min-width: 250px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 30px;
`;
const StyledDnsTitle = styled.div`
  font-size: ${isMobileOnly ? '14px' : '16px;'}
  color: ${theme.neutral4};
  font-weight: 600;
  display: flex;
  align-items: center;
`;

const StledDnsDescription = styled.div`
  font-size: 14px;
  line-height: 1.3;
  color: ${theme.neutralBase};
  font-weight: 400;
  display: flex;
`;

const StledDnsDescriptionBlock = styled.div`
  font-size: 14px;
  line-height: 1.3;
  color: ${theme.neutralBase};
  font-weight: 400;
  margin-bottom: 8px; 
`;

const StledDnsDescriptionBold = styled.span`
  font-size: 14px;
  line-height: 1.3;
  color: ${theme.neutralBase};
  font-weight: bold;
  padding-left: 5px;
`;

// New GreyedOutContainer component
const GreyedOutContainer = styled.div`
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
`;


const StyledTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${theme.neutralBase};
  padding-top: 20px;
  padding-bottom: 20px;
`;

const StyledUnderTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${theme.neutralBase};
  padding-top: 10px;
  padding-bottom: 10px;
`;

const FullscreenLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const Spinner = styled.div`
  border: 6px solid rgba(0, 0, 0, 0.1);
  border-top: 6px solid ${theme.primary || "#007bff"};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LoaderText = styled.p`
  margin-top: 15px;
  font-size: 18px;
  color: ${theme.neutralBase};
`;

const StyledTemplateControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;


const AccountInfo = ({
  onGoBack,
  onNextStep,
  products,
  onRemoveProductFromCart,
  deletingProductId,
  contact,
  disabledFields,
  countries,
  activeLanguage,
  s3_presigned_url,
  updateS3PresignedUrl,
  history,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const domainProducts = products.filter(product => product.type.includes("domain"));
  const hostingProduct = products.filter(product => product.type.includes("hosting"));

  useEffect(() => {
    const domainProducts = products.filter(product => product.type.includes("domain"));
    const transferOnly = domainProducts.every(product => product.config && product.config.type === "transfer");

    if (transferOnly) {
      onNextStep();
    } else if (domainProducts.length === 0) {
      history.push(ROUTE_CART_CHECKOUT);
    }
  }, [products, history]);

  const [isChecked, setIsChecked] = useState(false);
  const [showAddInfoModal, setShowAddInfoModal] = useState(false);
  const [showDataPreview, setShowDataPreview] = useState(false);
  const [showDomainRequirementsModal, setShowDomainRequirementsModal] = useState(false);
  const [checkedProducts, setCheckedProducts] = useState({});
  const [activeTab, setActiveTab] = useState("option1");
  const [maxUnlockedTab, setMaxUnlockedTab] = useState(1);
  const [dateError, setDateError] = useState("");

  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedAdministrativeCountry, setSelectedAdministrativeCountry] = useState('');
  const [shouldValidateMacedonianAdmin, setShouldValidateMacedonianAdmin] = useState(false);

  const [selectedTechnicalCountry, setSelectedTechnicalCountry] = useState('');
  const [shouldValidateMacedonianTechnical, setShouldValidateMacedonianTechnical] = useState(false);

  const [shouldValidateMacedonian, setShouldValidateMacedonian] = useState(false);
  const [selectedOption, setSelectedOption] = useState(hostingProduct.length > 0 ? "hostedInMkhost" : "customDnsZone");
  const [templateName, setTemplateName] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const isSaveButtonEnabled = templateName !== "" && isCheckboxChecked;
  const [currentStatusOption, setCurrentStatusOption] = useState("uploadNow");
  const [uploadedFile, setUploadedFile] = useState(null); // Track uploaded file
  const [selectedProduct, setSelectedProduct] = useState(products.length > 0 ? products[0] : null);
  const [domainTemplates, setDomainTemplates] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState({});
  const [fileError, setFileError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [restrictionMessageCheck, setRestrictionMessageCheck] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const [showMissingTekovnaModal, setShowMissingTekovnaModal] = useState(false);
  const [missingTekovnaTaxNumber, setMissingTekovnaTaxNumber] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [isTekovnaIssued, setIsTekovnaIssued] = useState(false);
  const [dnsSettingsByDomain, setDnsSettingsByDomain] = useState({});
  const [showDnsModal, setShowDnsModal] = useState(false);
  const [formData, setFormData] = useState({
    option1: {
      contact_type: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      company: "",
      tax_number: "",
      street_number: "",
      address: "",
      city: "",
      zip: "",
      country: "",
    },
    option2: {
      administrative_contact: {
        name: "",
        surname: "",
        email: "",
        phone: "",
        country: ""
      },
      technical_contact: {
        name: "",
        surname: "",
        email: "",
        phone: "",
        country: ""
      },
    },
    option3: {
      selected_dns_option: "customDnsZone",
      dns_server1: {
        name: "",
        value: "",
        address: "",
        fixed: false,
      },
      dns_server2: {
        name: "",
        value: "",
        address: "",
        fixed: false,
      },
      dns_server3: {
        name: "",
        value: "",
        address: "",
        fixed: false,
      },
    },
    option4: {
      registration_certificate: "",
      registration_certificate_date: "",
      order_registration_certificate: false,
    }
  });

  const getTemplateWithIcon = (template) => ({
    value: template.value,
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        {template.type === "individual" ? (
          <img src={iconPersonActive} alt="Person Icon" width={16} height={16} />
        ) : (
          <img src={iconBusinessActive} alt="Business Icon" width={16} height={16} />
        )}
        {template.label}
      </div>
    ),
  });

  const getTemplateOptions = () => {
    return domainTemplates.map(template => ({
      value: template.value,
      label: template.label,
      icon: template.type === "individual" ? iconPersonActive : iconBusinessActive,
      raw: template,
    }));
  };

  const getDefaultDnsForDomain = (domain, hostingProduct) => {
    // If hosting product exists use hosting defaults; else use zone defaults.
    if (hostingProduct && hostingProduct.length > 0) {
      return {
        ns1: "ns.mk-host.mk",
        ns2: "ns.mk-host.org",
        ns3: "",
        ns4: "",
        ns5: "",
        ip1: "",
        ip2: "",
        ip3: "",
        ip4: "",
        ip5: ""
      };
    }
    return {
      ns1: "dns1.mk-host.mk",
      ns2: "dns2.mk-host.mk",
      ns3: "",
      ns4: "",
      ns5: "",
      ip1: "",
      ip2: "",
      ip3: "",
      ip4: "",
      ip5: ""
    };
  };

  useEffect(() => {
    fetchDomainTemplates();
  }, [JSON.stringify(products)]);

  const fetchDomainTemplates = async () => {
    setIsLoadingTemplates(true);

    let allTemplates = [];
    let currentPage = 1;
    let totalPages = 1;

    try {
      const customerData = await fetchCustomerPersonalInformation();
      console.log("Customer data response:", customerData);

      if (!(customerData && customerData.data && customerData.data.data && customerData.data.data.customer && customerData.data.data.customer.id)) {
        throw new Error("Cannot fetch customer ID.");
      }
      const cart = getCartId();
      console.log("Cart ID:", cart);

      do {
        console.log(`Fetching templates for page ${currentPage}...`);
        const response = await getListDomainTemplates({ page: currentPage, limit: 10 });
        console.log(`Raw response for page ${currentPage}:`, response);

        if (!(response && response.data && response.data.data)) {
          console.warn(`No data found for page ${currentPage}. Full response:`, response);
          break;
        }

        const { templates, paging } = response.data.data;
        console.log(`Templates fetched on page ${currentPage}:`, templates);
        console.log(`Paging info for page ${currentPage}:`, paging);

        if (!templates || !paging) {
          console.warn(`Invalid response structure on page ${currentPage}. Templates:`, templates, "Paging:", paging);
          break;
        }

        allTemplates = [...allTemplates, ...templates];
        totalPages = paging.total_pages;
        console.log(`Total pages set to: ${totalPages}, Current page: ${currentPage}, Total templates so far: ${allTemplates.length}`);

        currentPage++;

      } while (currentPage <= totalPages);

      console.log("All fetched templates:", allTemplates);
      if (allTemplates.length === 0) {
        console.warn("No templates retrieved from the API after all pages.");
      }

      const formattedTemplates = allTemplates.map(template => ({
        value: template.id,
        label: template.template,
        type: template.type,
        country: template.registrant.country,
        tax_number: template.registrant.taxNumber || "",
        valid_until: template.valid_until || "",
      }));
      console.log("Formatted templates:", formattedTemplates);

      setDomainTemplates(formattedTemplates);

      const validDomainProducts = domainProducts.filter(
        product => product.type.includes("domain") && (!product.config || product.config.type !== "transfer")
      );
      console.log("Valid domain products to process:", validDomainProducts);

      let updatedSelectedTemplates = { ...selectedTemplates };
      let updatedCheckedProducts = { ...checkedProducts };
      let dnsUpdates = {};

      // Assign templates to valid domain products
      await Promise.all(validDomainProducts.map(async (product) => {
        try {
          const response = await getDomainTemplateById(product.id, cart);
          if (response && response.data && response.data.data && response.data.data.template) {
            const assignedTemplate = formattedTemplates.find(t => t.value === response.data.data.template.id);
            if (assignedTemplate) {
              updatedSelectedTemplates[product.domain] = getTemplateWithIcon(assignedTemplate);
              const dnsConfig = response.data.data.nameservers || {};
              dnsUpdates[product.domain] = {
                ns1: dnsConfig.ns1 || "",
                ns2: dnsConfig.ns2 || "",
                ns3: dnsConfig.ns3 || "",
                ns4: dnsConfig.ns4 || "",
                ns5: dnsConfig.ns5 || "",
                ip1: dnsConfig.ip1 || "",
                ip2: dnsConfig.ip2 || "",
                ip3: dnsConfig.ip3 || "",
                ip4: dnsConfig.ip4 || "",
                ip5: dnsConfig.ip5 || "",
              };
              updateDnsSettingsForDomain(product.domain, dnsUpdates[product.domain]);
              updatedCheckedProducts[product.id] = true;
            }
          }
        } catch (error) {
          console.error(`Error fetching template for ${product.domain}:`, error);
        }
      }));

      setSelectedTemplates(updatedSelectedTemplates);
      setCheckedProducts(updatedCheckedProducts);

      console.log("✅ Final fetched templates:", formattedTemplates);
      console.log("🎯 Final assigned templates:", updatedSelectedTemplates);
      console.log("✅ Final checked products:", updatedCheckedProducts);

      return formattedTemplates; // Return for potential external use
    } catch (error) {
      console.error("❌ Error fetching domain templates:", error.message || error);
      return []; // Return empty array on failure
    } finally {
      setIsLoadingTemplates(false); // Stop loading indicator
    }
  };

  const updateDnsSettingsForDomain = (domain, dnsConfig) => {
    setDnsSettingsByDomain(prev => {
      const newState = {
        ...prev,
        [domain]: { ...dnsConfig }
      };
      console.log(`Updated DNS settings for ${domain}:`, newState[domain]); // Debug
      return newState;
    });
  };

  const handleSaveTekovna = async () => {
    if (!selectedProduct || !selectedTemplateId) return;

    const updatedTemplates = await fetchDomainTemplates();

    const selectedTemplate = updatedTemplates.find(t => t.value === selectedTemplateId);
    if (!selectedTemplate) {
      console.warn("No matching template found for ID:", selectedTemplateId);
      return;
    }

    const formatted = getTemplateWithIcon(selectedTemplate);

    setSelectedTemplates(prev => ({
      ...prev,
      [selectedProduct.domain]: formatted
    }));

    setCheckedProducts(prev => ({
      ...prev,
      [selectedProduct.id]: true
    }));

    setShowMissingTekovnaModal(false);
    setTemplateName("")
  };


  const handleTaxNumberChange = async (event, form) => {
    const value = event.target.value;
    form.change("tax_number", value);
    if (value.length === 13) {
      setIsFetching(true);
      try {
        console.log(value)
        const response = await fetchDataFromUJP(value);
        if (response.error) {
          console.log("Error fetching company data: ", response.error);
        } else {
          console.log(response)
          const { name, address, city, phone, street_number } = response.data.data;
          form.change("company", name || "");
          form.change("address1", address || "");
          form.change("city", city || "");
          form.change("street_number", street_number || "");
          form.change("phone", phone || "");
        }
      } catch (error) {
        console.log("Error fetching company data: ", error);
      } finally {
        setIsFetching(false);
      }
    }
  };

  const handleTemplateChange = async (domain, selectedOption) => {
    if (!selectedOption) {
      console.warn(`⚠ No template selected for ${domain}`);
      return;
    }

    const selectedTemplateDetails = domainTemplates.find(template => template.value === selectedOption.value) || {};
    console.log("Selected template details:", selectedTemplateDetails);


    if (isMacedoniaDomain(domain)) {

      if (selectedTemplateDetails.type === CONTACT_DETAILS_TYPE_COMPANY) {
        if (!selectedTemplateDetails.tax_number) {
          alert("Tax number is missing");
          setMissingTekovnaTaxNumber("");
          setSelectedTemplateId("");
          return;
        }

        try {
          const response = await checkIfTemplateExists(selectedTemplateDetails.tax_number);
          console.log(`checkIfTemplateExists response:`, response.data);

          if (!response.data || response.data.error || !response.data.data.is_issued) {
            alert(translate("validation.template-without-document"));
            setMissingTekovnaTaxNumber(selectedTemplateDetails.tax_number);
            setSelectedTemplateId(selectedTemplateDetails.value);
            setTemplateName(selectedTemplateDetails)
            setShowMissingTekovnaModal(true);
            return;
          }
        } catch (error) {
          console.error(`Error checking template:`, error);
          alert(translate("cart.wizard.account-info-modal.validation.error-unexpected-occured"));
          setMissingTekovnaTaxNumber(selectedTemplateDetails.tax_number);
          setSelectedTemplateId(selectedTemplateDetails.value);
          setShowMissingTekovnaModal(true);
          return;
        }
      } else if (isCompanyOnlyDomain(domain) && selectedTemplateDetails.type === CONTACT_DETAILS_TYPE_INDIVIDUAL) {
        alert(translate("cart.wizard.account-info-modal.validation.this-domain-only-company-sec"));
        return;
      }
    }


    // const templateError = validateTemplateSelection(
    //   domain,
    //   selectedTemplateDetails.type || "",
    //   selectedTemplateDetails.country || "",
    //   translate
    // );

    // if (templateError) {
    //   alert(templateError);
    //   return;
    // }


    setSelectedTemplates((prev) => {
      const updatedTemplates = {
        ...prev,
        [domain]: {
          ...selectedOption,
          tax_number: selectedTemplateDetails.tax_number
        }
      };
      console.log("Updated selectedTemplates:", updatedTemplates);
      return updatedTemplates;
    });

    setCheckedProducts((prev) => ({
      ...prev,
      [domain]: true,
    }));

    console.log(`✅ Template selected for ${domain}:`, selectedOption);
  };

  const resetFormFields = () => {
    setFormData({
      option1: {
        contact_type: CONTACT_DETAILS_TYPE_INDIVIDUAL, // Default to Individual
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        company: "",
        tax_number: "",
        street_number: "",
        address: "",
        city: "",
        zip: "",
        country: "",
      },
      option2: {
        administrative_contact: {
          name: "",
          surname: "",
          email: "",
          phone: "",
          country: "",
        },
        technical_contact: {
          name: "",
          surname: "",
          email: "",
          phone: "",
          country: "",
        },
      },
      option3: {
        selected_dns_option: "",
        dns_servers: [],
      },
      option4: {
        registration_certificate: "",
        registration_certificate_date: "",
        order_registration_certificate: false,
      },
    });

    setHosts([
      { id: 1, name: "", value: "", address: "", fixed: true },
      { id: 2, name: "", value: "", address: "", fixed: true },
    ]);

    setTemplateName("");
    setSelectedOption("");
    // setIsCheckboxChecked(false);
    // setCheckedProducts({});
    setActiveTab("option1");
    setSelectedTemplates({});
    setSelectedCountry("");
  };

  const handleSaveTemplate = async () => {
    if (!templateName.trim()) {
      alert(translate("cart.wizard.account-info-modal.validation.error-enter-template-name"));
      return;
    }
    if (!isCheckboxChecked) {
      alert(translate("cart.wizard.account-info-modal.validation.error-accept-terms"));
      return;
    }

    // Normalize phone numbers directly here
    const normalizedRegistrantPhone = normalizeMacedonianPhoneNumber(formData.option1.phone.trim());
    const normalizedAdminPhone = normalizeMacedonianPhoneNumber(formData.option2.administrative_contact.phone.trim());
    const normalizedTechPhone = normalizeMacedonianPhoneNumber(formData.option2.technical_contact.phone.trim());

    // Logic that needs to be transfered in attach
    // let ns1 = "";
    // let ns2 = "";
    // let ip1 = "";
    // let ip2 = "";
    // let ns3 = "";
    // let ip3 = "";
    // let ns4 = "";
    // let ip4 = "";
    // let ns5 = "";
    // let ip5 = "";

    // if (formData.option3.selected_dns_option === "hostedInMkhost") {
    //   ns1 = "ns.mkhost.mk";
    //   ns2 = "ns.mkhost.org";
    // } else if (formData.option3.selected_dns_option === "customDnsZone") {
    //   ns1 = "dns1.mk-host.mk";
    //   ns2 = "dns2.mk-host.mk";
    // } else if (formData.option3.selected_dns_option === "customDnsServers") {
    //   const dnsServers = formData.option3.dns_servers || [];
    //   if (dnsServers.length > 0) ns1 = dnsServers[0].name || "";
    //   if (dnsServers.length > 0) ip1 = dnsServers[0].address || "";
    //   if (dnsServers.length > 1) ns2 = dnsServers[1].name || "";
    //   if (dnsServers.length > 1) ip2 = dnsServers[1].address || "";
    //   if (dnsServers.length > 2) ns3 = dnsServers[2].name || "";
    //   if (dnsServers.length > 2) ip3 = dnsServers[2].address || "";
    //   if (dnsServers.length > 3) ns4 = dnsServers[3].name || "";
    //   if (dnsServers.length > 3) ip4 = dnsServers[3].address || "";
    //   if (dnsServers.length > 4) ns5 = dnsServers[4].name || "";
    //   if (dnsServers.length > 4) ip5 = dnsServers[4].address || "";
    // }
    // END

    const payload = {
      template_name: templateName,
      registrant_first_name: formData.option1.first_name.trim() || "",
      registrant_last_name: formData.option1.last_name.trim() || "",
      registrant_company: formData.option1.company.trim() || "",
      registrant_street: formData.option1.address.trim() || "",
      registrant_street_number: formData.option1.street_number.trim(),
      registrant_postal_code: formData.option1.zip.trim() || "",
      registrant_city: formData.option1.city.trim() || "",
      registrant_country: formData.option1.country.value || "",
      registrant_tax_number: formData.option1.tax_number.trim() || "",
      registrant_phone_number: normalizedRegistrantPhone || "",
      registrant_email: formData.option1.email.trim() || "",
      administrative_contact_first_name: formData.option2.administrative_contact.name.trim() || "",
      administrative_contact_last_name: formData.option2.administrative_contact.surname.trim() || "",
      administrative_contact_email: formData.option2.administrative_contact.email.trim() || "",
      administrative_contact_phone_number: normalizedAdminPhone || "",
      administrative_contact_country: formData.option2.administrative_contact.country.value || "",
      technical_contact_first_name: formData.option2.technical_contact.name.trim() || "",
      technical_contact_last_name: formData.option2.technical_contact.surname.trim() || "",
      technical_contact_email: formData.option2.technical_contact.email.trim() || "",
      technical_contact_phone_number: normalizedTechPhone || "",
      technical_contact_country: formData.option2.technical_contact.country.value || "",
      registration_certificate: formData.option4.registration_certificate || "",
      registration_certificate_date: formData.option4.registration_certificate_date || null,
      order_registration_certificate: formData.option4.order_registration_certificate || false,
      // ns1,
      // ns2,
      // ip1,
      // ip2,
      // ns3,
      // ip3,
      // ns4,
      // ip4,
      // ns5,
      // ip5,
      type: formData.option1.contact_type || "individual",
    };

    try {
      setIsSaving(true);
      const response = await createNewDomainTemplate(payload);

      if (response.data) {
        alert(translate("cart.wizard.account-info-modal.validation.template-saved-successfully"));
        setShowDataPreview(false);
        setShowAddInfoModal(false);
        resetFormFields();
        setTemplateName("");

        const updatedTemplates = await fetchDomainTemplates();

        console.log("🔄 Updated Templates After Creation:", updatedTemplates);

        const newTemplate = updatedTemplates.find(t => t.label === templateName);

        if (newTemplate && selectedProduct) {
          setSelectedTemplates(prevTemplates => ({
            ...prevTemplates,
            [selectedProduct.domain]: newTemplate
          }));

          setCheckedProducts(prevChecked => ({
            ...prevChecked,
            [selectedProduct.domain]: true
          }));
        }
      } else {
        alert(translate("cart.wizard.account-info-modal.validation.error-unexpected-occured"));
      }
    } catch (error) {
      console.error("Error saving template:", error.message);
      alert(translate("cart.wizard.account-info-modal.validation.error-unexpected-occured"));
    } finally {
      setIsSaving(false);
    }
  };

  const handleNextStep = async () => {
    // The attach request happens here
    if (!isChecked) return;

    setIsSaving(true);
    const cartId = getCartId();
    let updatedPresignedUrls = {};

    // Separate normal domains and transfer domains
    const validDomainProducts = domainProducts.filter(
      product => product.type.includes("domain") && (!product.config || product.config.type !== "transfer")
    );
    // Separate transfer domains
    const transferDomains = domainProducts.filter(
      product => product.type.includes("domain") && product.config && product.config.type === "transfer"
    );

    // If ALL domains are transfer, skip processing and move to next step
    if (validDomainProducts.length === 0 && transferDomains.length > 0) {
      setIsSaving(false);
      onNextStep();
      return;
    }

    for (const product of validDomainProducts) {
      if (!selectedTemplates || typeof selectedTemplates !== "object") {
        console.error("selectedTemplates is not an object:", selectedTemplates);
        alert(translate("cart.wizard.account-info-modal.validation.error-unexpected"));
        setIsSaving(false);
        return;
      }

      const selectedTemplate = selectedTemplates[product.domain];
      const dnsConfig = dnsSettingsByDomain[product.domain] || getDnsSetupFromForm(formData);

      if (!selectedTemplate) {
        alert(translate("cart.wizard.account-info-modal.validation.error-no-selected-template") + product.domain);
        setIsSaving(false);
        return;
      }


      const assignmentData = {
        template_id: selectedTemplate.value,
        item_id: product.id,
        cart: cartId,
        ns1: dnsConfig.ns1 ? dnsConfig.ns1 : "dns1.mk-host.mk",
        ns2: dnsConfig.ns2 ? dnsConfig.ns2 : "dns2.mk-host.mk",
        ns3: dnsConfig.ns3,
        ns4: dnsConfig.ns4,
        ns5: dnsConfig.ns5,
        ip1: dnsConfig.ip1,
        ip2: dnsConfig.ip2,
        ip3: dnsConfig.ip3,
        ip4: dnsConfig.ip4,
        ip5: dnsConfig.ip5,
      };

      console.log("Sending request for:", JSON.stringify(assignmentData, null, 2));
      console.log(assignmentData.ns1,
        assignmentData.ns2,
        assignmentData.ns3,
        assignmentData.ns4,
        assignmentData.ns5,
        assignmentData.ip1,
        assignmentData.ip2,
        assignmentData.ip3,
        assignmentData.ip4,
        assignmentData.ip5)
      try {
        const response = await assignTemplateToDomain(
          assignmentData.template_id,
          assignmentData.item_id,
          assignmentData.cart,
          assignmentData.ns1,
          assignmentData.ns2,
          assignmentData.ns3,
          assignmentData.ns4,
          assignmentData.ns5,
          assignmentData.ip1,
          assignmentData.ip2,
          assignmentData.ip3,
          assignmentData.ip4,
          assignmentData.ip5
        );

        if (!response || response.error) {
          throw new Error(`Error assigning template to ${product.domain}: ${response.message || "Unknown error"}`);
        }

        console.log(`✅ Template assigned successfully for ${product.domain}:`, response);

        // ** Ensure response.data and response.data.data exist before accessing marnet_document **
        if (
          isMacedoniaDomain(product.domain) &&
          response.data &&
          response.data.data &&
          response.data.data.marnet_document
        ) {
          updatedPresignedUrls[product.domain] = response.data.data.marnet_document;
          console.log(`🔗 Presigned URL set for ${product.domain}:`, updatedPresignedUrls[product.domain]);
        } else {
          console.warn(`⚠️ Skipping presigned URL update for ${product.domain}, as it is not a .mk or .мкд domain.`);
        }

      } catch (error) {
        console.error("Error processing templates:", error);
        alert(translate("cart.wizard.account-info-modal.validation.error-while-assigning-template"), { domain: product.domain });
        setIsSaving(false);
        return;
      }
    }

    // Update s3_presigned_url in the cart's state
    updateS3PresignedUrl(updatedPresignedUrls);
    console.log("Updated s3_presigned_url:", updatedPresignedUrls);

    setIsSaving(false);
    onNextStep();
  };

  const formatDateToDDMMYYYY = (date) => {
    if (!date) return "";
    const [year, month, day] = date.split("-");
    return `${day}-${month}-${year}`;
  };



  const uploadDocumentSignedRequest = async (domain, file, options = {}) => {
    console.log(options)
    setIsSubmitting(true);
    try {
      if (!domain) {
        throw new Error("Не е избран домен.");
      }
      if (!file) {
        throw new Error("Ве молиме прикачете документ.");
      }

      const customerData = await fetchCustomerPersonalInformation();
      if (!customerData || !customerData.data.data.customer.id) {
        throw new Error("Не можам да го најдам клиентскиот ID. Обиди се повторно.");
      }

      const clientId = customerData.data.data.customer.id;
      const deliveryType = "digital-manual";
      const domainAction = "register";

      // Prepare FormData
      const formData1 = new FormData();
      formData1.append("domain", domain);
      formData1.append("client_id", clientId);
      formData1.append("delivery_type", deliveryType);
      formData1.append("signature_status", "signed");
      formData1.append("domain_action", domainAction);
      formData1.append("document_type", "registration_certificate");
      formData1.append("attachment", file);
      formData1.append("tax_number", options.tax_number || formData.option1.tax_number || ""); // Fallback to formData if not provided
      formData1.append("certificate_date", options.certificate_date || formatDateToDDMMYYYY(formData.option4.registration_certificate_date) || "");

      console.log("Uploading document with FormData:", Object.fromEntries(formData1.entries()));

      // Make API request
      const response = await uploadDocumentSignedV2(formData1);
      console.log('Document link: ', response.data.data.document);
      if (!response.data) {
        throw new Error("Неуспешно прикачување на документ.");
      }

      const documentLink = response.data.data.document;
      console.log("✅ Document Uploaded. Link:", documentLink);

      // Update formData in AccountInfo (only if called from AccountInfo context)
      if (!options.fromMissingTekovna) {
        setFormData((prevData) => ({
          ...prevData,
          option4: {
            ...prevData.option4,
            registration_certificate: documentLink,
          },
        }));
      }

      setIsSubmitting(false);
      return true; // Indicate success
    } catch (error) {
      console.error(`Грешка при праќање на документ за ${domain}:`, error);
      alert(error.message || translate("cart.wizard.account-info-modal.validation.error-while-uploading-document"));
      setIsSubmitting(false);
      return false; // Indicate failure
    }
  };

  const handleFileUpload = (file) => {
    if (!file) {
      console.error("No file selected.");
      return;
    }

    console.log(`Selected file:`, file);

    setFileError(""); // Clear error
    setUploadedFile(file);

    setFormData((prevData) => ({
      ...prevData,
      option4: {
        ...prevData.option4,
        registration_certificate: file.name,
      },
    }));
  };


  const handleFormSubmit = async (values, form) => {
    console.log("Submitting values:", values);

    // Clear fields depending on contact type
    if (values.contact_type === CONTACT_DETAILS_TYPE_COMPANY) {
      form.change("first_name", "");
      form.change("last_name", "");
    } else {
      form.change("company", "");
      form.change("tax_number", "");
    }

    // Set formData
    setFormData((prevData) => ({
      ...prevData,
      option1: {
        ...prevData.option1,
        contact_type: values.contact_type,
        first_name: values.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL ? values.first_name || "" : "",
        last_name: values.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL ? values.last_name || "" : "",
        email: values.email || "",
        phone: values.phone || "",
        company: values.contact_type === CONTACT_DETAILS_TYPE_COMPANY ? values.company || "" : "",
        tax_number: values.contact_type === CONTACT_DETAILS_TYPE_COMPANY ? values.tax_number || "" : "",
        address: values.address1 || prevData.option1.address,
        street_number: values.street_number || prevData.option1.street_number,
        city: values.city || prevData.option1.city,
        zip: values.zip || prevData.option1.zip,
        country: values.country ? values.country : prevData.option1.country,
      },
    }));

    // 🔍 Check tekovna only if COMPANY and tax_number is present
    if (values.contact_type === CONTACT_DETAILS_TYPE_COMPANY && values.tax_number) {
      try {
        const response = await checkIfTemplateExists(values.tax_number);
        const data = response && response.data && response.data.data;
        const isIssued = data && data.is_issued;
        setIsTekovnaIssued(!!isIssued);
      } catch (err) {
        console.error("Tekovna check failed:", err);
        setIsTekovnaIssued(false);
      }
    } else {
      setIsTekovnaIssued(false);
    }

    setActiveTab("option2");
  };

  const getDnsSetupFromForm = (formData) => {
    let ns1 = "", ns2 = "", ip1 = "", ip2 = "";
    let ns3 = "", ip3 = "", ns4 = "", ip4 = "", ns5 = "", ip5 = "";

    if (formData.option3.selected_dns_option === "hostedInMkhost") {
      ns1 = "ns.mkhost.mk";
      ns2 = "ns.mkhost.org";
    } else if (formData.option3.selected_dns_option === "customDnsZone") {
      ns1 = "dns1.mk-host.mk";
      ns2 = "dns2.mk-host.mk";
    } else if (formData.option3.selected_dns_option === "customDnsServers") {
      const dnsServers = formData.option3.dns_servers || [];
      if (dnsServers[0]) { ns1 = dnsServers[0].name || ""; ip1 = dnsServers[0].address || ""; }
      if (dnsServers[1]) { ns2 = dnsServers[1].name || ""; ip2 = dnsServers[1].address || ""; }
      if (dnsServers[2]) { ns3 = dnsServers[2].name || ""; ip3 = dnsServers[2].address || ""; }
      if (dnsServers[3]) { ns4 = dnsServers[3].name || ""; ip4 = dnsServers[3].address || ""; }
      if (dnsServers[4]) { ns5 = dnsServers[4].name || ""; ip5 = dnsServers[4].address || ""; }
    }

    return { ns1, ns2, ns3, ns4, ns5, ip1, ip2, ip3, ip4, ip5 };
  };


  const validateRegistrationDate = (date) => {
    if (!date) {
      setDateError("Изберете датум на тековна состојба.");
      return false;
    }

    const selectedDate = new Date(date);
    const today = new Date();
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(today.getMonth() - 6);

    if (selectedDate < sixMonthsAgo) {
      setDateError(translate("cart.wizard.account-info-modal.validation.date-cannot-be-in-past"));
      return false;
    }

    if (selectedDate > today) {
      setDateError(translate("cart.wizard.account-info-modal.validation.date-cannot-be-in-future"));
      return false;
    }

    setDateError("");
    return true;
  };


  const validateAccountDetails = (values = {}) => {
    const errors = {};

    console.log("validateAccountDetails:", { values, shouldValidateMacedonian });

    errors.email = values.email ? validateEmail(values.email) : translate("validation.enter-email");
    errors.phone = values.phone ? validateMkPhoneNumber(values.phone) : translate("validation.enter-phone-number");
    errors.country = values.country && values.country.value
      ? validateCountry(values.country.value)
      : translate("cart.wizard.account-info-modal.validation.enter-country");

    if (!values.contact_type) {
      errors.contact_type = translate("cart.wizard.account-info-modal.validation.select-entity-type");
    } else if (values.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL) {
      errors.first_name = values.first_name ? validateFirstName(values.first_name) : translate("validation.enter-first-name");
      errors.last_name = values.last_name ? validateLastName(values.last_name) : translate("validation.enter-last-name");
    } else if (values.contact_type === CONTACT_DETAILS_TYPE_COMPANY) {
      if (!values.tax_number || isEmpty(values.tax_number)) {
        errors.tax_number = translate("validation.enter-tax-number");
      } else if (shouldValidateMacedonian && values.tax_number.length !== 13) {
        errors.tax_number = translate("validation.tax-number-must-be-13-digits");
      } else if (shouldValidateMacedonian && !/^\d+$/.test(values.tax_number)) {
        errors.tax_number = translate("validation.tax-number-numeric");
      }
      errors.company = values.company ? undefined : translate("validation.enter-company");
    }

    return errors;
  };

  const validateDnsSettings = (values = {}, hosts = [], domainName, translate, doesHostnameMatchDomain) => {
    const errors = {};

    // First pass: Validate hostnames and corresponding IP addresses
    hosts.forEach((host, index) => {
      const serverField = `dnsServer${index + 1}`;
      const addressField = `dnsAddress${index + 1}`;

      const serverValue = values[serverField];
      const addressValue = values[addressField];

      // Hostname validation: required check
      if (!serverValue || serverValue.trim() === "") {
        errors[serverField] = translate("cart.wizard.account-info-modal.validation.required-hostname");
      } else {
        const hostnameRegex = /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})*$/;
        if (!hostnameRegex.test(serverValue.trim())) {
          errors[serverField] = translate("cart.wizard.account-info-modal.validation.invalid-hostname-format");
        }
      }

      if (doesHostnameMatchDomain(serverValue, domainName)) {
        if (!addressValue || addressValue.trim() === "") {
          errors[addressField] = translate("cart.wizard.account-info-modal.validation.required-ip");
        } else {
          const ipRegex = /^(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(?:\.(?!$)|$)){4}$/;
          if (!ipRegex.test(addressValue.trim())) {
            errors[addressField] = translate("cart.wizard.account-info-modal.validation.invalid-ip-format");
          }
        }
      }
    });

    const nameserverValues = hosts.map((host, index) => {
      return values[`dnsServer${index + 1}`] ? values[`dnsServer${index + 1}`].trim().toLowerCase() : "";
    });

    const frequency = nameserverValues.reduce((acc, value, index) => {
      if (value) {
        if (acc[value]) {
          acc[value].push(index);
        } else {
          acc[value] = [index];
        }
      }
      return acc;
    }, {});

    Object.entries(frequency).forEach(([value, indices]) => {
      if (indices.length > 1) {
        indices.forEach((i) => {
          const field = `dnsServer${i + 1}`;
          errors[field] = translate("cart.wizard.account-info-modal.validation.duplicate-nameserver");
        });
      }
    });

    return errors;
  };



  const hasMacedonianDomains = () => {
    return domainProducts.some(product => isMacedoniaDomain(product.domain));
  };

  const isMacedoniaDomain = (domain) => {
    const macedonianDomains = ['.mk', '.мкд', '.com.mk', '.net.mk', '.org.mk', '.edu.mk', '.inf.mk'];
    return macedonianDomains.some(ext => domain.endsWith(ext));
  };

  // There is no Individual only domain in Macedonia
  // const isIndividualOnlyDomain = (domain) => {
  //   const individualOnlyDomains = ['.mk', '.мкд'];
  //   return individualOnlyDomains.some(ext => domain.endsWith(ext));
  // };

  const isMacedoniaOnlyDomain = (domain) => {
    const macedoniaOnlyDomains = ['.mk', '.org.mk', '.net.mk', '.inf.mk', '.edu.mk'];
    return macedoniaOnlyDomains.some(ext => domain.endsWith(ext));
  };

  // Check if a domain is **Company-Only**
  const isCompanyOnlyDomain = (domain) => {
    const companyOnlyDomains = ['.com.mk', '.org.mk', '.net.mk', '.edu.mk', '.inf.mk'];
    return companyOnlyDomains.some(ext => domain.endsWith(ext));
  };


  const getRestrictionMessage = (domain, contactType, country) => {
    if (isCompanyOnlyDomain(domain) && contactType === CONTACT_DETAILS_TYPE_INDIVIDUAL) {
      setRestrictionMessageCheck(true);
      return translate("cart.wizard.account-info-modal.validation.this-domain-only-company-sec");
    }
    // Country !== "Macedonia"
    if (isMacedoniaOnlyDomain(domain) && contactType === CONTACT_DETAILS_TYPE_INDIVIDUAL && country !== "Macedonia") {
      setRestrictionMessageCheck(true);
      return translate("cart.wizard.account-info-modal.validation.this-domain-only-macedonian-sec");
    }

    setRestrictionMessageCheck(false);
    return "";
  };


  const validateTemplateSelection = (domain, contactType, country) => {
    console.log("🔎 Checking template selection...");
    console.log("➡ Domain:", domain);
    console.log("➡ Contact Type:", contactType);
    console.log("➡ Country:", country);

    if (isCompanyOnlyDomain(domain) && contactType === CONTACT_DETAILS_TYPE_INDIVIDUAL) {
      return translate("cart.wizard.account-info-modal.validation.this-domain-only-company");
    }
    // Country !== "Macedonia"
    if (isMacedoniaOnlyDomain(domain) && country !== "Macedonia") {
      return translate("cart.wizard.account-info-modal.validation.this-domain-only-macedonian");
    }

    return "";
  };

  const validateContactDetails = (values) => {
    const errors = {};

    if (values.administrative_contact) {
      errors.administrative_contact = {
        name: validateFirstName(values.administrative_contact.name),
        surname: validateLastName(values.administrative_contact.surname),
        email: validateEmail(values.administrative_contact.email),
        phone: validateMkPhoneNumber(values.administrative_contact.phone),
        country: values.administrative_contact.country && values.administrative_contact.country.value
          ? validateCountry(values.administrative_contact.country.value)
          : translate("cart.wizard.account-info-modal.validation.enter-country")
      };
    }

    if (values.technical_contact) {
      errors.technical_contact = {
        name: validateFirstName(values.technical_contact.name),
        surname: validateLastName(values.technical_contact.surname),
        email: validateEmail(values.technical_contact.email),
        phone: validateMkPhoneNumber(values.technical_contact.phone),
        country: values.technical_contact.country && values.technical_contact.country.value
          ? validateCountry(values.technical_contact.country.value)
          : translate("cart.wizard.account-info-modal.validation.enter-country") // This ensures the error appears if country is missing
      };
    }

    return errors;
  };

  const handleContactSubmit = (values) => {
    console.log("Updating formData with values:", values);
    setFormData((prevData) => ({
      ...prevData,
      option2: {
        administrative_contact: {
          name: values.administrative_contact && values.administrative_contact.name.trim() ? values.administrative_contact.name.trim() : "",
          surname: values.administrative_contact && values.administrative_contact.surname.trim() ? values.administrative_contact.surname.trim() : "",
          email: values.administrative_contact && values.administrative_contact.email.trim() ? values.administrative_contact.email.trim() : "",
          phone: values.administrative_contact && values.administrative_contact.phone.trim() ? values.administrative_contact.phone.trim() : "",
          country: values.administrative_contact && values.administrative_contact.country ? values.administrative_contact.country : "",
        },
        technical_contact: {
          name: values.technical_contact && values.technical_contact.name.trim() ? values.technical_contact.name.trim() : "",
          surname: values.technical_contact && values.technical_contact.surname.trim() ? values.technical_contact.surname.trim() : "",
          email: values.technical_contact && values.technical_contact.email.trim() ? values.technical_contact.email.trim() : "",
          phone: values.technical_contact && values.technical_contact.phone.trim() ? values.technical_contact.phone.trim() : "",
          country: values.technical_contact && values.technical_contact.country ? values.technical_contact.country : "",
        },
      },
    }));
  };

  const handleDnsSubmit = (domain) => {
    setShowDnsModal(false);
    let dns_servers = [];

    if (selectedOption === "hostedInMkhost") {
      dns_servers = [
        { name: "ns.mkhost.mk", address: "" },
        { name: "ns.mkhost.org", address: "" }
      ];
    } else if (selectedOption === "customDnsZone") {
      dns_servers = [
        { name: "dns1.mk-host.mk", address: "" },
        { name: "dns2.mk-host.mk", address: "" }
      ];
    } else if (selectedOption === "customDnsServers") {
      const dnsEntries = hosts
        .filter((host) => host.value.trim() !== "")
        .map((host) => ({
          name: host.value,
          address: host.address || "",
        }));
      dns_servers = dnsEntries;
    }

    const updatedForm = {
      selected_dns_option: selectedOption,
      dns_servers,
    };

    const dnsConfig = getDnsSetupFromForm({ option3: updatedForm });
    updateDnsSettingsForDomain(domain, dnsConfig); // Immediately update DNS settings
    setFormData((prevData) => ({
      ...prevData,
      option3: updatedForm,
    }));

    if (selectedOption === "customDnsServers") {
      setHosts([
        { id: 1, name: translate("cart.wizard.account-info-modal.option3.ns-name-of-host") + " 1", value: "", address: "", fixed: true },
        { id: 2, name: translate("cart.wizard.account-info-modal.option3.ns-name-of-host") + " 2", value: "", address: "", fixed: true },
      ]);
    }
  };

  const handleDnsOptionChange = (optionId) => {
    setSelectedOption(optionId);

    // If switching away from custom DNS, clear saved DNS servers
    if (optionId !== "customDnsServers") {
      setFormData((prevData) => ({
        ...prevData,
        option3: {
          selected_dns_option: optionId,
          dns_servers: [], // Reset custom DNS data
        },
      }));
    }
  };


  const handleModalPrimaryClick = async () => {
    if (activeTab === "option1") {
      document.getElementById("domain-contact-details-form").dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    }
    else if (activeTab === "option2") {
      document.getElementById("contact-details-form").dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    }
    // else if (activeTab === "option3") {
    //   if (!selectedOption) {
    //     return;
    //   }

    //   if (selectedOption === "customDnsServers") {
    //     const hasMissingIp = hosts.some(host => doesHostnameMatchDomain(host.value, domainName) && !host.address.trim());

    //     if (hasMissingIp) {
    //       alert(translate("cart.wizard.account-info-modal.validation.you-have-to-input-ip-address"));
    //       return;
    //     }
    //   }

    //   handleDnsSubmit();

    //   const isCompanyRegistrant = formData.option1.contact_type === CONTACT_DETAILS_TYPE_COMPANY;
    //   const isMacedonianDomain = isMacedoniaDomain(selectedProduct.domain);

    // if (isCompanyRegistrant && isMacedonianDomain && !isTekovnaIssued) {
    //   setActiveTab("option4");
    // } else {
    //   setShowDataPreview(true);
    // }
    // }
    if (activeTab === "option4") {
      console.log("currentStatusOption:", currentStatusOption)
      if (currentStatusOption === "uploadNow") {
        if (!validateRegistrationDate(formData.option4.registration_certificate_date)) {
          return;
        }

        setIsSaving(true);
        const domain = selectedProduct ? selectedProduct.domain : null;

        const uploadSuccess = await uploadDocumentSignedRequest(domain, uploadedFile);

        if (uploadSuccess) {
          setShowDataPreview(true);
        }

        setIsSaving(false);
      } else if (currentStatusOption === "mkHostFetch") {
        formData.option4.order_registration_certificate = true;
        setShowDataPreview(true);
      } else {
        setShowDataPreview(true);
      }
    }
  };


  // TODO: Check this
  const [hosts, setHosts] = useState([
    { id: 1, name: translate("cart.wizard.account-info-modal.option3.ns-name-of-host") + " 1", value: "", address: "", fixed: true },
    { id: 2, name: translate("cart.wizard.account-info-modal.option3.ns-name-of-host") + " 2", value: "", address: "", fixed: true },
  ]);

  const maxHosts = 5;
  const domainName = products.length > 0 ? products[0].item.name : "";

  // Function to add a new host (up to 5 max)
  const addHost = () => {
    if (hosts.length < maxHosts) {
      setHosts([...hosts, { id: Date.now(), name: "", address: "", fixed: false }]);
    }
  };

  // Function to remove an additional host (only if it's not fixed)
  const removeHost = (id) => {
    setHosts(hosts.filter((host) => host.id !== id));
  };

  const updateHost = (id, field, value) => {
    setHosts(prevHosts =>
      prevHosts.map(host => {
        if (host.id !== id) return host;

        const updated = { ...host, [field]: value };

        // Check if IP is required
        const requiresIp = doesHostnameMatchDomain(
          field === "value" ? value : host.value,
          domainName
        );

        const ip = field === "address" ? value : host.address;
        const ipRegex = /^(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(?:\.(?!$)|$)){4}$/;

        return {
          ...updated,
          isIpRequired: requiresIp,
          isIpValid: !requiresIp || ipRegex.test(ip.trim()),
        };
      })
    );
  };


  const doesHostnameMatchDomain = (hostValue, domain) => {
    if (typeof hostValue !== "string") {
      console.warn("Invalid hostValue:", hostValue);
      return false;
    }
    return hostValue.endsWith(`.${domain}`);
  };

  const getPrimaryButtonLabel = () => {
    return activeTab === "option4"
      ? translate("cart.wizard.account-info-modal.view")
      : translate("cart.wizard.account-info-modal.save-and-continue");
  };


  const handleCountryChange = (selectedOption, form) => {
    console.log(selectedOption)
    form.change("country", selectedOption);
    setFormData((prevData) => ({
      ...prevData,
      option1: {
        ...prevData.option1,
        country: selectedOption,
      },
    }));
    setShouldValidateMacedonian(selectedOption.label === "Macedonia");
  };


  const handleCountryChangeAdministrative = (selectedOption) => {
    setSelectedAdministrativeCountry(selectedOption);
    setShouldValidateMacedonianAdmin(selectedOption.label === "Macedonia");
  };

  const handleCountryChangeTechnical = (selectedOption) => {
    setSelectedTechnicalCountry(selectedOption);
    setShouldValidateMacedonianTechnical(selectedOption.label === "Macedonia");
  };

  const minLength = min => (value) =>
    value && value.length < min
      ? translate("validation.enter-tax-number")
      : undefined;

  const validateTaxNumber = (value, values = {}) => {
    if (values.contact_type === "company") {
      if (!value || isEmpty(value)) {
        return translate("validation.enter-tax-number");
      }
      if (!/^\d+$/.test(value)) {
        return translate("validation.tax-number-numeric");
      }

    }
    return undefined; // No error if not required
  };


  useEffect(() => {
    if (activeLanguage.code === 'mk') {
      const macedonia = countries.find(country => country.label === 'Macedonia');
      if (macedonia) {
        setSelectedCountry(macedonia);
        setShouldValidateMacedonian(true);
      }
    }
  }, [activeLanguage, countries]);

  const openAddInfoModal = (product) => {
    setShowAddInfoModal(true);
    setSelectedProduct(product);
    const macedonia = countries.find(country => country.label === 'Macedonia');
    if (activeLanguage.code === 'mk' && macedonia) {
      setSelectedCountry(macedonia);
      setShouldValidateMacedonian(true);
    } else {
      setSelectedCountry(""); // Or another default
      setShouldValidateMacedonian(false);
    }
  };

  const openDnsModal = (product) => {
    const currentDns = dnsSettingsByDomain[product.domain];
    if (!currentDns || Object.keys(currentDns).length === 0) {
      const defaultDns = getDefaultDnsForDomain(product.domain, hostingProduct);
      updateDnsSettingsForDomain(product.domain, defaultDns);
    }
    setShowDnsModal(true);
    setSelectedProduct(product);
  };


  const closeAddInfoModal = () => {
    setShowAddInfoModal(false);
    setActiveTab("option1");
    setFormData(prevData => ({
      ...prevData,
      option1: {
        contact_type: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        company: "",
        tax_number: "",
        street_number: "",
        address: "",
        city: "",
        zip: "",
        country: "",
      },
    }));
    setSelectedProduct(null);
    setSelectedCountry("");
    setShouldValidateMacedonian(false);
  };


  const handleCheckboxChange = (productId) => {
    setCheckedProducts(prevState => {
      const updatedState = {
        ...prevState,
        [productId]: !prevState[productId],
      };

      // Filter out transfer domains
      const validDomainProducts = domainProducts.filter(
        p => p.type.includes("domain") && (!p.config || p.config.type !== "transfer")
      );

      // Check if all non-transfer domains are checked
      const allChecked = validDomainProducts.every(p => updatedState[p.id]);

      setIsChecked(allChecked);

      return updatedState;
    });
  };

  useEffect(() => {
    // Filter out transfer domains
    const validDomainProducts = domainProducts.filter(
      p => p.type.includes("domain") && (!p.config || p.config.type !== "transfer")
    );

    const allTemplatesSelected = validDomainProducts.every(p => selectedTemplates[p.domain]);
    const allTermsChecked = validDomainProducts.every(p => checkedProducts[p.id]);

    setIsChecked(allTemplatesSelected && allTermsChecked);
  }, [selectedTemplates, checkedProducts, products]);


  const capitalizeFirstLetter = (text) => {
    if (!text) return "";

    // Split into words and spaces, process words, then rejoin
    return text
      .split(/(\s+)/) // Split into words AND spaces (keeps all whitespace)
      .map((token) => {
        // Only capitalize tokens that are NOT whitespace
        if (token.trim() === "") return token; // Leave spaces unchanged
        return token.charAt(0).toUpperCase() + token.slice(1);
      })
      .join(""); // Rejoin tokens (spaces preserved)
  };


  const handleCheckboxChangeAcceptTerms = () => {
    setIsCheckboxChecked(prev => !prev);
  };

  return (
    <Translate>
      {({ translate }) => (
        <div className="row">
          <div className="col-xl-12 col-sm-12 col-md-12 ml-md-3">
            {hasMacedonianDomains() && (
              <>
                <StyledRow>
                  <Icon size="large" icon="warning" color="#fe9b34" />
                  <StyledText>{translate("cart.wizard.account-info-modal.additional-requirement")}</StyledText>
                </StyledRow>
                <StyledDescription>
                  {translate("cart.wizard.account-info-modal.additional-requirement-description")}
                </StyledDescription>
              </>
            )}
            {products.map(product => (
              product.type.includes("domain") &&
              (
                product.config.type !== "transfer" ? (
                  <div className="mb-5" key={product.id}>
                    <Container>
                      <StyledCheckbox>
                        <CheckboxInline onClick={() => handleCheckboxChange(product.id)}>
                          <Checkbox
                            checked={!!checkedProducts[product.id]}
                            input={{ onChange: () => handleCheckboxChange(product.id), value: !!checkedProducts[product.id] }}
                            label={
                              <>
                                {translate("cart.wizard.account-info-modal.checkbox-rules-first")}                                                {" "}
                                <button
                                  className="font-weight-bold "
                                  style={{ background: "none", border: "none", color: "#007bff", textDecoration: "underline", cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowDomainRequirementsModal(true);
                                    setSelectedProduct(product); // Ensure the correct domain is set
                                  }}
                                >
                                  {translate("cart.wizard.account-info-modal.checkbox-rules-second")}
                                </button>
                                {translate("cart.wizard.account-info-modal.checkbox-rules-third")}
                                {hasMacedonianDomains() && (
                                  <>
                                    {translate("cart.wizard.account-info-modal.checkbox-rules-fourth")}
                                    {" "}
                                    <a
                                      className="font-weight-bold"
                                      target="_blank"
                                      href="https://mkhost-public-assets.s3.eu-central-1.amazonaws.com/pravilnik-mk-mkd.pdf"
                                      rel="noopener noreferrer"
                                    >
                                      {translate("cart.wizard.account-info-modal.checkbox-rules-fifth")}
                                    </a>
                                  </>
                                )}
                              </>
                            }
                          />
                        </CheckboxInline>
                      </StyledCheckbox>
                    </Container>
                    <GreyedOutContainer disabled={!checkedProducts[product.id]}>
                      <Container>
                        <StyledDomain>
                          <StyledDomainSection key={product.id}>
                            <StyledRow className="row">
                              <div className="col-12 col-sm-12 col-md-12">
                                <StyledDeleteIcon
                                  isMobile={isMobile}
                                  isDeleting={deletingProductId === product.id}
                                  onClick={() =>
                                    deletingProductId !== product.id &&
                                    onRemoveProductFromCart(product)
                                  }
                                >
                                  {deletingProductId !== product.id && (
                                    <Icon size="l" icon="delete" />
                                  )}
                                  {deletingProductId === product.id && (
                                    <DeletingRecordLoader />
                                  )}
                                </StyledDeleteIcon>
                                <StyledTypeTitle>
                                  {product.config.type === CART_DOMAIN_TYPE_REGISTER
                                    ? translate("cart.wizard.account-info-modal.register-domain")
                                    : translate("cart.wizard.account-info-modal.transfer-domain")
                                  }
                                </StyledTypeTitle>
                                <StyledDomainName>{product.domain}</StyledDomainName>
                              </div>
                            </StyledRow>
                          </StyledDomainSection>
                        </StyledDomain>
                      </Container>
                      <Container>
                        <StyledWrapper>
                          <StyledSection>
                            <StyledRow>
                              <Icon size="large" icon="warning" color="#fe9b34" />
                              <StyledText>{translate("cart.wizard.account-info-modal.domain-registration-information")}</StyledText>
                            </StyledRow>
                            {domainTemplates.length > 0 ? (
                              <div className="row mt-4">
                                <div className="col-xl-6 col-lg-6 col-md-12">
                                  <SelectBox
                                    className={isMobileOnly ? 'mt-3 ' : ''}
                                    label={translate("cart.wizard.account-info-modal.choose-a-template")}
                                    options={getTemplateOptions()}
                                    placeholder={translate("cart.wizard.account-info-modal.choose-a-template-short")}
                                    name={`selectedTemplate-${product.domain}`}
                                    isSearchable={true}
                                    value={getTemplateOptions().find(opt =>
                                      selectedTemplates[product.domain] &&
                                      opt.value === selectedTemplates[product.domain].value
                                    )}
                                    onChange={(selectedOption) => handleTemplateChange(product.domain, selectedOption)}
                                    formatOptionLabel={({ label, icon }) => (
                                      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                        <img src={icon} alt="Type Icon" width={16} height={16} />
                                        {label}
                                      </div>
                                    )}
                                  />

                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 d-flex justify-content-end">
                                  {/* <StyledDescriptionTemplate>
                                    {translate("cart.wizard.account-info-modal.cant-find-the-right-tempalte")}
                                  </StyledDescriptionTemplate> */}
                                  <SecondaryButton className={isMobileOnly ? 'mt-3 w-100 ' : 'mt-4'} onClick={() => openAddInfoModal(product)}>
                                    {translate("cart.wizard.account-info-modal.add-new-template")}
                                  </SecondaryButton>
                                </div>
                              </div>
                            ) : (
                              <>
                                <StyledDescription>
                                  {translate("cart.wizard.account-info-modal.you-dont-have-template-created")}
                                </StyledDescription>
                                <PrimaryButton onClick={() => openAddInfoModal(product)}>{translate("cart.wizard.account-info-modal.add-data")}</PrimaryButton>
                              </>
                            )}

                            <div className="col-xl-12 col-sm-12 col-md-12 p-0 m-0">
                              <div className="col-12 p-0 m-0 d-flex align-items-center">
                                <StyledDnsTitle>
                                  {translate("cart.wizard.account-info-modal.predefined-nameservers")}
                                </StyledDnsTitle>
                                <StyledCustomTooltip>
                                  <CustomTooltip content={translate("cart.wizard.account-info-modal.predefined-tooltip")}>
                                    <Icon size="m" icon="info" />
                                  </CustomTooltip>
                                </StyledCustomTooltip>
                              </div>
                              {(() => {
                                const domainConfig = dnsSettingsByDomain[product.domain] || {};
                                const nameservers = ['ns1', 'ns2', 'ns3', 'ns4', 'ns5']
                                  .map((key, index) => {
                                    const ns = domainConfig[key];
                                    const ip = domainConfig["ip" + (index + 1)];
                                    return ns ? (
                                      <StledDnsDescriptionBlock key={key} className="mt-2">
                                        Опслужувач {index + 1}: <StledDnsDescriptionBold>{ns}</StledDnsDescriptionBold>
                                        {ip && (
                                          <span style={{ marginLeft: 10 }}>
                                            IP-{index + 1}: <StledDnsDescriptionBold>{ip}</StledDnsDescriptionBold>
                                          </span>
                                        )}
                                      </StledDnsDescriptionBlock>
                                    ) : null;
                                  })
                                  .filter(Boolean);

                                if (nameservers.length === 0) {
                                  return hostingProduct.length > 0 ? (
                                    <>
                                      <StledDnsDescription className="mt-2">
                                        {translate("domain.settings.nameserver")} 1: <StledDnsDescriptionBold>ns.mk-host.mk</StledDnsDescriptionBold>
                                      </StledDnsDescription>
                                      <StledDnsDescription className="mt-1">
                                        {translate("domain.settings.nameserver")} 2: <StledDnsDescriptionBold>ns.mk-host.org</StledDnsDescriptionBold>
                                      </StledDnsDescription>
                                    </>
                                  ) : (
                                    <>
                                      <StledDnsDescription className="mt-2">
                                        {translate("domain.settings.nameserver")} 1: <StledDnsDescriptionBold>dns1.mk-host.mk</StledDnsDescriptionBold>
                                      </StledDnsDescription>
                                      <StledDnsDescription className="mt-1">
                                        {translate("domain.settings.nameserver")} 2: <StledDnsDescriptionBold>dns2.mk-host.mk</StledDnsDescriptionBold>
                                      </StledDnsDescription>
                                    </>
                                  );
                                }
                                return nameservers;
                              })()}
                              <LinkButton className="mt-2" onClick={() => {
                                openDnsModal(product);
                              }}>
                                {translate("cart.wizard.account-info-modal.click-to-change-dns")}
                              </LinkButton>
                            </div>

                          </StyledSection>
                        </StyledWrapper>
                      </Container>

                    </GreyedOutContainer>
                  </div>
                ) : (
                  <div className="mb-5" key={product.id}>
                    <Container>
                      <StyledDomain>
                        <StyledDomainSection key={product.id}>
                          <StyledRow className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                              <StyledDeleteIcon
                                isMobile={isMobile}
                                isDeleting={deletingProductId === product.id}
                                onClick={() =>
                                  deletingProductId !== product.id &&
                                  onRemoveProductFromCart(product)
                                }
                              >
                                {deletingProductId !== product.id && (
                                  <Icon size="l" icon="delete" />
                                )}
                                {deletingProductId === product.id && (
                                  <DeletingRecordLoader />
                                )}
                              </StyledDeleteIcon>
                              <StyledTypeTitle>
                                {translate("cart.wizard.account-info-modal.transfer-domain")}
                              </StyledTypeTitle>
                              <StyledDomainName>{product.domain}</StyledDomainName>
                              <StyledDescription>
                                {translate("cart.wizard.account-info-modal.no-template-needed")}
                              </StyledDescription>
                            </div>
                          </StyledRow>
                        </StyledDomainSection>
                      </StyledDomain>
                    </Container>
                  </div>

                )
              )
            ))}


            <StyledFooter>
              <SecondaryButton className={isMobileOnly ? "w-50" : ""} onClick={onGoBack}>{translate("cart.wizard.account-info-modal.go-back")}</SecondaryButton>
              <PrimaryButton className={isMobileOnly ? "w-50" : ""} onClick={handleNextStep} disabled={!isChecked || isSaving} submitting={isSaving}>
                {isSaving ? translate("cart.wizard.account-info-modal.saving") : translate("cart.wizard.account-info-modal.next")}
              </PrimaryButton>

            </StyledFooter>
          </div>
          {showAddInfoModal && (
            <Modal
              isOpen={showAddInfoModal}
              onCloseModal={() => closeAddInfoModal()}
              title={`${translate("cart.wizard.account-info-modal.domain-registration-information")} - ${selectedProduct ? selectedProduct.domain : ''}`}
              size="xxl"
              // Pass in a custom header that renders the tab buttons
              tabButtons={true}
              underHeader={() => (
                <TabContainer>
                  <TabButtons>
                    <TabButton
                      active={activeTab === "option1"}
                      disabled={false}
                      onClick={() => setActiveTab("option1")}
                    >
                      {translate("cart.wizard.account-info-modal.registrant")}
                    </TabButton>
                    <TabButton
                      active={activeTab === "option2"}
                      disabled={maxUnlockedTab < 2}
                      onClick={() => maxUnlockedTab >= 2 && setActiveTab("option2")}
                    >
                      {translate("cart.wizard.account-info-modal.contact-data")}
                    </TabButton>
                    {/* <TabButton
                      active={activeTab === "option3"}
                      disabled={maxUnlockedTab < 3}
                      onClick={() => maxUnlockedTab >= 3 && setActiveTab("option3")}
                    >
                      {translate("cart.wizard.account-info-modal.dns-servers")}
                    </TabButton> */}
                    {isMacedoniaDomain(selectedProduct.domain) &&
                      formData.option1.contact_type === CONTACT_DETAILS_TYPE_COMPANY &&
                      !isTekovnaIssued && (
                        <TabButton
                          active={activeTab === "option4"}
                          disabled={maxUnlockedTab < 4}
                          onClick={() => maxUnlockedTab >= 4 && setActiveTab("option4")}
                        >
                          {translate("cart.wizard.account-info-modal.documents-for-acceptance")}
                        </TabButton>
                      )}
                  </TabButtons>
                </TabContainer>
              )}
              body={() => (
                <>
                  {activeTab === "option1" && (

                    <TabContainer>
                      <TabContent>

                        <StyledDescription>
                          {translate("cart.wizard.account-info-modal.entity-type-for-domain-registration")}
                        </StyledDescription>
                        <Form
                          onSubmit={handleFormSubmit}
                          validate={validateAccountDetails}
                          initialValues={{
                            ...contact,
                            country: selectedCountry,
                            contact_type: formData.option1.contact_type || CONTACT_DETAILS_TYPE_INDIVIDUAL,
                            first_name: formData.option1.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL
                              ? formData.option1.first_name || ""
                              : "", // Reset if not Individual
                            last_name: formData.option1.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL
                              ? formData.option1.last_name || ""
                              : "", // Reset if not Individual
                            email: formData.option1.email || "",
                            phone: formData.option1.phone || "",
                            company: formData.option1.contact_type === CONTACT_DETAILS_TYPE_COMPANY
                              ? formData.option1.company || ""
                              : "", // Reset if not Company
                            tax_number: formData.option1.contact_type === CONTACT_DETAILS_TYPE_COMPANY
                              ? formData.option1.tax_number || ""
                              : "", // Reset if not Company
                            address1: formData.option1.address || "",
                            street_number: formData.option1.street_number || "",
                            city: formData.option1.city || "",
                            zip: formData.option1.zip || "",
                          }}
                          render={({ handleSubmit, values, submitting, invalid, form }) => {

                            const handleResetField = (fieldName1, fieldName2) => {
                              form.change(fieldName1, "");
                              form.change(fieldName2, "");
                            };
                            const restrictionMessage = getRestrictionMessage(selectedProduct.domain, values.contact_type, values.country && values.country.label);
                            return (
                              <form
                                id="domain-contact-details-form"
                                onSubmit={handleSubmit}
                              >
                                <div className="row">
                                  <div className="col-xl-12 col-sm-12 col-md-12">

                                    <div className="row mb-2">
                                      <div className="col-xl-3 col-md-6 mb-2" onClick={() => handleResetField(CONTACT_DETAILS_TYPE_COMPANY, "tax_number")}>
                                        <Field
                                          component={BlockRadioboxNew}
                                          activeIcon={iconPersonActive}
                                          defaultIcon={iconPersonInactive}
                                          name="contact_type"
                                          value={CONTACT_DETAILS_TYPE_INDIVIDUAL}
                                          type="radio"
                                          defaultValue={CONTACT_DETAILS_TYPE_INDIVIDUAL}
                                        >
                                          {translate("cart.wizard.individual")}
                                        </Field>
                                      </div>
                                      <div className="col-xl-3 col-md-6">
                                        <Field
                                          component={BlockRadioboxNew}
                                          activeIcon={iconBusinessActive}
                                          defaultIcon={iconBusinessInactive}
                                          name="contact_type"
                                          value={CONTACT_DETAILS_TYPE_COMPANY}
                                          type="radio"
                                        >
                                          {translate("cart.wizard.company")}
                                        </Field>
                                      </div>
                                    </div>
                                    {values.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL && (
                                      <div className="row">
                                        <div className="col-12 col-sm-6 col-md-6">
                                          <Field
                                            component={InputField}
                                            label={translate("cart.wizard.first-name")}
                                            name="first_name"
                                            readOnly={disabledFields && !!disabledFields.first_name}
                                            required
                                            validate={
                                              shouldValidateMacedonian
                                                ? (value) => {
                                                  if (!value) {
                                                    return translate("validation.enter-tax-number");
                                                  }
                                                  if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                    return translate("domain.domain-contacts.must-be-cyrillic");
                                                  }
                                                }
                                                : undefined
                                            }
                                            parse={(value) => {
                                              if (!value) return "";

                                              let processedValue = value;

                                              if (shouldValidateMacedonian) {
                                                processedValue = transliterateToCyrilic(processedValue);
                                              }

                                              return capitalizeFirstLetter(processedValue);
                                            }}
                                          />
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-6">
                                          <Field
                                            component={InputField}
                                            label={translate("cart.wizard.last-name")}
                                            name="last_name"
                                            readOnly={disabledFields && !!disabledFields.last_name}
                                            required
                                            validate={
                                              shouldValidateMacedonian
                                                ? (value) => {
                                                  if (!value) {
                                                    return translate("validation.enter-tax-number");
                                                  }
                                                  if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                    return translate("domain.domain-contacts.must-be-cyrillic");
                                                  }
                                                }
                                                : undefined
                                            }
                                            parse={(value) => {
                                              if (!value) return "";

                                              let processedValue = value;

                                              if (shouldValidateMacedonian) {
                                                processedValue = transliterateToCyrilic(processedValue);
                                              }

                                              return capitalizeFirstLetter(processedValue);
                                            }} />
                                        </div>
                                      </div>
                                    )}
                                    {values.contact_type === CONTACT_DETAILS_TYPE_COMPANY && (
                                      <div className="row">
                                        <div className="col-12">
                                          <Field
                                            component={InputField}
                                            label={translate("cart.wizard.tax-number")}
                                            name="tax_number"
                                            validate={
                                              shouldValidateMacedonian
                                                ? (value) => {
                                                  if (!value) {
                                                    return translate("validation.enter-tax-number");
                                                  }
                                                  if (!/^\d+$/.test(value)) {
                                                    return translate("validation.tax-number-numeric");
                                                  }
                                                  if (value.length !== 13) {
                                                    return translate("validation.tax-number-must-be-13-digits");
                                                  }
                                                }
                                                : undefined
                                            }
                                            onChange={(event) => handleTaxNumberChange(event, form)}
                                            required
                                          />
                                        </div>
                                        <div className="col-12">
                                          <Field
                                            component={InputField}
                                            label={translate("cart.wizard.company")}
                                            name="company"
                                            validate={
                                              shouldValidateMacedonian
                                                ? (value) => {
                                                  if (!value) {
                                                    return translate("validation.enter-company");
                                                  }
                                                  if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                    return translate("domain.domain-contacts.must-be-cyrillic");
                                                  }
                                                }
                                                : undefined
                                            }
                                            required
                                          />
                                        </div>
                                      </div>
                                    )}
                                    <div className="row">
                                      <div className="col-12 col-sm-6 col-md-6">
                                        <div className="col-12 col-sm-12 col-md-12 p-0 m-0">
                                          <PhoneNumberInput
                                            name="phone"
                                            label={translate("cart.wizard.phone-number")}
                                            placeholder={translate("cart.wizard.phone-number")}
                                            defaultCountry={shouldValidateMacedonian ? 'MK' : undefined}
                                            translate={translate}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-12 col-sm-6 col-md-6">
                                        <Field
                                          component={InputField}
                                          label={translate("cart.wizard.email")}
                                          name="email"
                                          required
                                        />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-xl-9 col-md-12">
                                        <Field
                                          component={InputField}
                                          label={translate("cart.wizard.address")}
                                          name="address1"
                                          required
                                          validate={
                                            shouldValidateMacedonian
                                              ? (value) => {
                                                if (!value) {
                                                  return translate("validation.enter-tax-number");
                                                }
                                                if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                  return translate("domain.domain-contacts.must-be-cyrillic");
                                                }
                                              }
                                              : undefined
                                          }
                                          parse={(value) => {
                                            if (!value) return "";

                                            let processedValue = value;

                                            if (shouldValidateMacedonian) {
                                              processedValue = transliterateToCyrilic(processedValue);
                                            }

                                            return capitalizeFirstLetter(processedValue);
                                          }} />
                                      </div>
                                      <div className="col-xl-3 col-md-12">
                                        <Field
                                          component={InputField}
                                          label={translate("domain.domain-contacts.appartment-number")}
                                          name="street_number"
                                          required
                                          validate={
                                            shouldValidateMacedonian
                                              ? (value) => {
                                                if (!value) {
                                                  return translate("validation.enter-tax-number");
                                                }
                                                if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                  return translate("domain.domain-contacts.must-be-cyrillic");
                                                }
                                              }
                                              : undefined
                                          }
                                          parse={(value) => {
                                            if (!value) return "";

                                            let processedValue = value;

                                            if (shouldValidateMacedonian) {
                                              processedValue = transliterateToCyrilic(processedValue);
                                            }

                                            return capitalizeFirstLetter(processedValue);
                                          }} />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-xl-3 col-md-12">
                                        <Field
                                          component={InputField}
                                          label={translate("cart.wizard.city")}
                                          name="city"
                                          required
                                          validate={
                                            shouldValidateMacedonian
                                              ? (value) => {
                                                if (!value) {
                                                  return translate("validation.enter-tax-number");
                                                }
                                                if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                  return translate("domain.domain-contacts.must-be-cyrillic");
                                                }
                                              }
                                              : undefined
                                          }
                                          parse={(value) => {
                                            if (!value) return "";

                                            let processedValue = value;

                                            if (shouldValidateMacedonian) {
                                              processedValue = transliterateToCyrilic(processedValue);
                                            }

                                            return capitalizeFirstLetter(processedValue);
                                          }}
                                        />
                                      </div>
                                      <div className="col-xl-3 col-md-12">
                                        <Field
                                          component={InputField}
                                          label={translate("cart.wizard.zip-code")}
                                          name="zip"
                                          required
                                          validate={
                                            shouldValidateMacedonian
                                              ? (value) => {
                                                if (!value) {
                                                  return translate("validation.enter-tax-number");
                                                }
                                              }
                                              : undefined
                                          }
                                        />
                                      </div>
                                      <div className="col-xl-6 col-md-12">
                                        <Field
                                          component={SelectBox}
                                          label={translate("domain.domain-contacts.country")}
                                          options={countries}
                                          isSearchable={true}
                                          name="country"
                                          required
                                          onChange={(selectedOption) => handleCountryChange(selectedOption, form)}
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12">
                                        {shouldValidateMacedonian && (
                                          <StyledCyrillic>
                                            {translate("domain.domain-contacts.must-be-cyrillic")}
                                          </StyledCyrillic>
                                        )}
                                      </div>
                                    </div>
                                    {restrictionMessage && (
                                      <div className="row">
                                        <div className="col-12 mt-3">
                                          <StyledRow>
                                            <Icon size="large" icon="warning" color="#fe9b34" />
                                            <StyledErrorText>{restrictionMessage}</StyledErrorText>
                                          </StyledRow>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </form>
                            );
                          }}
                        />
                      </TabContent>
                    </TabContainer>
                  )}
                  {activeTab === "option2" && (
                    <>
                      <StyledRow>
                        <Icon size="large" icon="warning" color="#fe9b34" />
                        <StyledErrorText>{translate("cart.wizard.account-info-modal.info-header")}</StyledErrorText>
                      </StyledRow>
                      <TabContainer>
                        <TabContent>
                          <Form
                            onSubmit={(values) => {
                              console.log("Submitting values:", values); // Debugging line
                              handleContactSubmit(values);
                              setMaxUnlockedTab((prev) => Math.max(prev, 3));

                              const isMacedonianDomain = isMacedoniaDomain(selectedProduct.domain);
                              const isCompanyRegistrant = formData.option1.contact_type === CONTACT_DETAILS_TYPE_COMPANY;

                              if (isCompanyRegistrant && isMacedonianDomain && !isTekovnaIssued) {
                                setActiveTab("option4");
                              } else {
                                setShowDataPreview(true);
                              }
                            }}
                            validate={validateContactDetails}
                            initialValues={formData.option2}
                            render={({ handleSubmit, values, form }) => (
                              <form id="contact-details-form" onSubmit={handleSubmit}>
                                {/* Administrative Contact */}
                                <StyledContactHeader>
                                  <div className="col-12 p-0 m-0 d-flex align-items-center">
                                    <StyledContactTitle>{translate("cart.wizard.account-info-modal.administrative-contact")}</StyledContactTitle>
                                    <StyledCustomTooltip>
                                      <CustomTooltip content={translate("cart.wizard.account-info-modal.administrative-contact-tooltip")}>
                                        <Icon size="l" icon="info" />
                                      </CustomTooltip>
                                    </StyledCustomTooltip>
                                  </div>
                                  {formData.option1.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL && (
                                    <div className="ml-auto">
                                      <SecondaryButton
                                        style={{ padding: "5px 15px", fontSize: "14px" }}
                                        onClick={(e) => {
                                          e.preventDefault(); // Prevent form submission
                                          form.batch(() => {
                                            form.change("administrative_contact.name", formData.option1.first_name || "");
                                            form.change("administrative_contact.surname", formData.option1.last_name || "");
                                            form.change("administrative_contact.email", formData.option1.email || "");
                                            form.change("administrative_contact.phone", formData.option1.phone || "");
                                            form.change("administrative_contact.country", formData.option1.country || "");
                                          });
                                        }}
                                      >
                                        {translate("cart.wizard.account-info-modal.copy-from-registrant")}
                                      </SecondaryButton>
                                    </div>
                                  )}
                                </StyledContactHeader>

                                <div className="row">
                                  <div className="col-6">
                                    <Field
                                      component={SelectBox}
                                      label={translate("domain.domain-contacts.country")}
                                      options={countries}
                                      isSearchable={true}
                                      name="administrative_contact.country"
                                      required
                                      onChange={handleCountryChangeAdministrative}
                                      value={selectedAdministrativeCountry}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6">
                                    <Field name="administrative_contact.name" component={InputField} label={translate("cart.wizard.first-name")} required validate={
                                      shouldValidateMacedonianAdmin
                                        ? (value) => {
                                          if (!value) {
                                            return translate("validation.enter-first-name");
                                          }
                                          if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                            return translate("domain.domain-contacts.must-be-cyrillic");
                                          }
                                        }
                                        : undefined
                                    }
                                      parse={(value) => {
                                        if (!value) return "";

                                        let processedValue = value;

                                        if (shouldValidateMacedonianAdmin) {
                                          processedValue = transliterateToCyrilic(processedValue);
                                        }

                                        return capitalizeFirstLetter(processedValue);
                                      }}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <Field name="administrative_contact.surname" component={InputField} label={translate("cart.wizard.last-name")} required
                                      validate={
                                        shouldValidateMacedonianAdmin
                                          ? (value) => {
                                            if (!value) {
                                              return translate("validation.enter-last-name");
                                            }
                                            if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                              return translate("domain.domain-contacts.must-be-cyrillic");
                                            }
                                          }
                                          : undefined
                                      }
                                      parse={(value) => {
                                        if (!value) return "";

                                        let processedValue = value;

                                        if (shouldValidateMacedonianAdmin) {
                                          processedValue = transliterateToCyrilic(processedValue);
                                        }

                                        return capitalizeFirstLetter(processedValue);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6">
                                    <PhoneNumberInput
                                      name="administrative_contact.phone"
                                      label={translate("cart.wizard.phone-number")}
                                      placeholder={translate("cart.wizard.phone-number")}
                                      defaultCountry={shouldValidateMacedonianAdmin ? 'MK' : undefined}
                                      translate={translate}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <Field
                                      name="administrative_contact.email"
                                      component={InputField}
                                      label={translate("cart.wizard.email")}
                                      required
                                    />
                                  </div>
                                </div>

                                {/* Technical Contact */}
                                <StyledContactHeader>
                                  <div className="col-12 p-0 m-0 d-flex align-items-center">
                                    <StyledContactTitle>
                                      {translate("cart.wizard.account-info-modal.technical-contact")}
                                    </StyledContactTitle>
                                    <StyledCustomTooltip>
                                      <CustomTooltip content={translate("cart.wizard.account-info-modal.technical-contact-tooltip")}>
                                        <Icon size="l" icon="info" />
                                      </CustomTooltip>
                                    </StyledCustomTooltip>

                                  </div>
                                  {formData.option1.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL && (
                                    <div className="ml-auto">
                                      <SecondaryButton
                                        style={{ padding: "5px 15px", fontSize: "14px" }}
                                        onClick={(e) => {
                                          e.preventDefault(); // Prevent form submission
                                          form.batch(() => {
                                            form.change("technical_contact.name", formData.option1.first_name || "");
                                            form.change("technical_contact.surname", formData.option1.last_name || "");
                                            form.change("technical_contact.email", formData.option1.email || "");
                                            form.change("technical_contact.phone", formData.option1.phone || "");
                                            form.change("technical_contact.country", formData.option1.country || "");
                                          });
                                        }}
                                      >
                                        {translate("cart.wizard.account-info-modal.copy-from-registrant")}
                                      </SecondaryButton>
                                    </div>
                                  )}
                                  {formData.option1.contact_type === CONTACT_DETAILS_TYPE_COMPANY && (
                                    values.administrative_contact &&
                                    values.administrative_contact.name &&
                                    values.administrative_contact.surname &&
                                    values.administrative_contact.email &&
                                    values.administrative_contact.phone &&
                                    values.administrative_contact.country && (
                                      <div className="ml-auto">
                                        <SecondaryButton
                                          style={{ padding: "5px 15px", fontSize: "14px" }}
                                          onClick={(e) => {
                                            e.preventDefault(); // Prevent form submission

                                            form.batch(() => {
                                              form.change("technical_contact.name", values.administrative_contact.name || "");
                                              form.change("technical_contact.surname", values.administrative_contact.surname || "");
                                              form.change("technical_contact.email", values.administrative_contact.email || "");
                                              form.change("technical_contact.phone", values.administrative_contact.phone || "");
                                              form.change("technical_contact.country", values.administrative_contact.country || "");
                                            });
                                          }}
                                        >
                                          {translate("cart.wizard.account-info-modal.copy-from-administrative")}
                                        </SecondaryButton>
                                      </div>
                                    )
                                  )}
                                </StyledContactHeader>

                                <div className="row">
                                  <div className="col-6">
                                    <Field
                                      component={SelectBox}
                                      label={translate("domain.domain-contacts.country")}
                                      options={countries}
                                      isSearchable={true}
                                      name="technical_contact.country"
                                      required
                                      onChange={handleCountryChangeTechnical}
                                      value={selectedTechnicalCountry}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6">
                                    <Field name="technical_contact.name" required component={InputField} label={translate("cart.wizard.first-name")} validate={
                                      shouldValidateMacedonianTechnical
                                        ? (value) => {
                                          if (!value) {
                                            return translate("validation.enter-first-name");
                                          }
                                          if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                            return translate("domain.domain-contacts.must-be-cyrillic");
                                          }
                                        }
                                        : undefined
                                    }
                                      parse={(value) => {
                                        if (!value) return "";

                                        let processedValue = value;

                                        if (shouldValidateMacedonianTechnical) {
                                          processedValue = transliterateToCyrilic(processedValue);
                                        }

                                        return capitalizeFirstLetter(processedValue);
                                      }}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <Field name="technical_contact.surname" required component={InputField} label={translate("cart.wizard.last-name")} validate={
                                      shouldValidateMacedonianTechnical
                                        ? (value) => {
                                          if (!value) {
                                            return translate("validation.enter-last-name");
                                          }
                                          if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                            return translate("domain.domain-contacts.must-be-cyrillic");
                                          }
                                        }
                                        : undefined
                                    }
                                      parse={(value) => {
                                        if (!value) return "";

                                        let processedValue = value;

                                        if (shouldValidateMacedonianTechnical) {
                                          processedValue = transliterateToCyrilic(processedValue);
                                        }

                                        return capitalizeFirstLetter(processedValue);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6">
                                    <PhoneNumberInput
                                      name="technical_contact.phone"
                                      label={translate("cart.wizard.phone-number")}
                                      placeholder={translate("cart.wizard.phone-number")}
                                      defaultCountry={shouldValidateMacedonianTechnical ? 'MK' : undefined}
                                      translate={translate}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <Field name="technical_contact.email" required component={InputField} label={translate("cart.wizard.email")} />
                                  </div>
                                </div>
                              </form>
                            )}
                          />
                        </TabContent>
                      </TabContainer>
                    </>
                  )}
                  {/* {activeTab === "option3" && (
                    <TabContainer>
                      <TabContent>
                        <StyledDescription>{translate("cart.wizard.account-info-modal.choose-one-option")}</StyledDescription>
                        {[
                          {
                            id: "customDnsZone",
                            label: translate("cart.wizard.account-info-modal.option3.label-1"),
                            description: translate("cart.wizard.account-info-modal.option3.description-1"),
                            tooltip: translate("cart.wizard.account-info-modal.option3.tooltip-1")
                          },
                          {
                            id: "hostedInMkhost",
                            label: translate("cart.wizard.account-info-modal.option3.label-2"),
                            description: translate("cart.wizard.account-info-modal.option3.description-2"),
                            tooltip: translate("cart.wizard.account-info-modal.option3.tooltip-2"),
                          },
                          {
                            id: "customDnsServers",
                            label: translate("cart.wizard.account-info-modal.option3.label-3"),
                            description: translate("cart.wizard.account-info-modal.option3.description-3"),
                            tooltip: translate("cart.wizard.account-info-modal.option3.tooltip-3"),
                          },
                        ].map((option) => (
                          <div key={option.id}>
                            <StyledRow style={{ alignItems: 'center' }}>
                              <Radiobox
                                name="dnsOptions"
                                value={option.id}
                                onChange={() => handleDnsOptionChange(option.id)}
                                checked={selectedOption === option.id}
                                className="py-2"
                              >
                                {option.label}
                              </Radiobox>
                              <StyledCustomTooltip>
                                <CustomTooltip content={option.tooltip}>
                                  <Icon size="s" icon="info" />
                                </CustomTooltip>
                              </StyledCustomTooltip>
                            </StyledRow>
                            {selectedOption === option.id && (
                              <div className="ml-4 mt-0">
                                {option.id === "hostedInMkhost" && (
                                  <div>
                                    <StledDnsDescription>
                                      {translate("cart.wizard.account-info-modal.option3.ns-label")} 1: <StledDnsDescriptionBold>ns.mkhost.mk</StledDnsDescriptionBold>
                                    </StledDnsDescription>
                                    <StledDnsDescription>
                                      {translate("cart.wizard.account-info-modal.option3.ns-label")} 2: <StledDnsDescriptionBold>ns.mkhost.org</StledDnsDescriptionBold>
                                    </StledDnsDescription>
                                  </div>
                                )}
                                {option.id === "customDnsZone" && (
                                  <div>
                                    <StledDnsDescription>
                                      {translate("cart.wizard.account-info-modal.option3.ns-label")} 1: <StledDnsDescriptionBold>dns1.mk-host.mk</StledDnsDescriptionBold>
                                    </StledDnsDescription>
                                    <StledDnsDescription>
                                      {translate("cart.wizard.account-info-modal.option3.ns-label")} 2: <StledDnsDescriptionBold>dns2.mk-host.mk</StledDnsDescriptionBold>
                                    </StledDnsDescription>
                                  </div>
                                )}
                                {option.id === "customDnsServers" && (
                                  <div>
                                    {hosts.map((host, index) => (
                                      <div className="row" key={host.id}>
                                        <div className="col-xl-5">
                                          <InputField
                                            label={`${translate("cart.wizard.account-info-modal.option3.ns-name-of-host")} ${index + 1}`}
                                            placeholder={translate("cart.wizard.account-info-modal.option3.ns-name-of-host")}
                                            name={`dnsServer${index + 1}`}
                                            className="w-100"
                                            value={host.value}
                                            onChange={(e) => updateHost(host.id, "value", e.target.value)}
                                          />
                                        </div>
                                        {doesHostnameMatchDomain(host.value, domainName) && (
                                          <div className="col-xl-5">
                                            <InputField
                                              label={`${translate("cart.wizard.account-info-modal.option3.address-label")} ${index + 1}`}
                                              placeholder={translate("cart.wizard.account-info-modal.option3.address-placeholder")}
                                              name={`dnsAddress${index + 1}`}
                                              className="w-100"
                                              value={host.address}
                                              onChange={(e) => updateHost(host.id, "address", e.target.value)}
                                              required={host.isIpRequired} // Enforce required field
                                            />
                                          </div>
                                        )}
                                        {!host.fixed && (
                                          <div className="col-xl-2 mt-4">
                                            <button
                                              type="button"
                                              onClick={() => removeHost(host.id)}
                                              className="btn btn-danger"
                                            >
                                              X
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    ))}

                                    {hosts.length < maxHosts && (
                                      <div className="text-right mt-3">
                                        <PrimaryButton onClick={addHost}>+ {translate("cart.wizard.account-info-modal.option3.add-more-ns")}</PrimaryButton>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                      </TabContent>
                    </TabContainer>
                  )} */}
                  {activeTab === "option4" && (
                    <TabContainer>
                      <TabContent>
                        <StyledRow>
                          <Icon size="large" icon="warning" color="#fe9b34" />
                          <StyledSmallText>
                            {translate("cart.wizard.account-info-modal.option4.you-must-uplaod-documents")}
                          </StyledSmallText>
                        </StyledRow>

                        <StyledTitle>{translate("cart.wizard.account-info-modal.option4.upload-documents")}</StyledTitle>
                        <StyledUnderTitle>
                          {translate("cart.wizard.account-info-modal.option4.documents-description")}
                        </StyledUnderTitle>
                        <StyledBorder className="mt-4 mb-4" />

                        <StyledUnderTitle>{translate("cart.wizard.account-info-modal.option4.choose-document-sending-method")}</StyledUnderTitle>

                        <Radiobox
                          name="currentStatusOption"
                          value="uploadNow"
                          onChange={() => setCurrentStatusOption("uploadNow")}
                          checked={currentStatusOption === "uploadNow"}
                        >
                          {translate("cart.wizard.account-info-modal.option4.add-documents-now")}
                        </Radiobox>

                        {currentStatusOption === "uploadNow" && (
                          <div className="mb-3">
                            <StyledUnderTitle>{translate("cart.wizard.account-info-modal.option4.upload-document-label")}:</StyledUnderTitle>
                            <FileUploadButton
                              acceptFileExtensions=".pdf"
                              onFileSelect={handleFileUpload}
                              label="cart.wizard.account-info-modal.option4.upload-document-button"
                            />

                            {uploadedFile && (
                              <StyledUnderTitle>{translate("cart.wizard.account-info-modal.option4.selected-document")}: {uploadedFile.name}</StyledUnderTitle>
                            )}
                            {fileError && (
                              <StyledSmallText style={{ color: "red", marginTop: "5px" }}>
                                {fileError}
                              </StyledSmallText>
                            )}
                            <div className="mt-3">
                              <InputField
                                label={translate("cart.wizard.account-info-modal.option4.date-of-registration-certificate")}
                                type="date"
                                name="registration_certificate_date"
                                value={formData.option4.registration_certificate_date || ""}
                                onChange={(e) => {
                                  const selectedDate = e.target.value;
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    option4: {
                                      ...prevData.option4,
                                      registration_certificate_date: selectedDate,
                                    },
                                  }));
                                  validateRegistrationDate(selectedDate);
                                }}
                                required
                              />
                              {dateError && (
                                <StyledSmallText style={{ color: "red", marginTop: "5px" }}>
                                  {dateError}
                                </StyledSmallText>
                              )}
                            </div>
                          </div>
                        )}

                        {formData.option1.country && formData.option1.country.label === "Macedonia" && (
                          <Radiobox
                            name="currentStatusOption"
                            value="mkHostFetch"
                            onChange={() => setCurrentStatusOption("mkHostFetch")}
                            checked={currentStatusOption === "mkHostFetch"}
                          >
                            {translate("cart.wizard.account-info-modal.option4.let-mkhost-get-it")}
                          </Radiobox>
                        )}
                      </TabContent>
                    </TabContainer>
                  )}


                  <StyledFooter className="d-flex justify-content-end w-100">
                    <SecondaryButton
                      className={isMobileOnly ? 'w-50 ' : ''}
                      style={{ marginRight: '15px' }}
                      onClick={() => {
                        if (activeTab === "option1") {
                          setShowAddInfoModal(false);
                        } else {
                          setActiveTab((prev) => {
                            if (prev === "option2") return "option1";
                            if (prev === "option3") return "option2";
                            if (prev === "option4") return "option3";
                            return prev;
                          });
                        }
                      }}
                    >
                      {activeTab === "option1"
                        ? translate("cart.wizard.back-to-cart")
                        : translate("cart.wizard.go-back")}
                    </SecondaryButton>

                    <PrimaryButton
                      className={isMobileOnly ? 'w-50 ' : ''}
                      onClick={handleModalPrimaryClick}
                      disabled={(!!restrictionMessageCheck && !isSubmitting) || isSaving}
                      submitting={isSubmitting}
                    >
                      {isSaving
                        ? translate("cart.wizard.account-info-modal.saving")
                        : getPrimaryButtonLabel()}
                    </PrimaryButton>

                  </StyledFooter>
                  <div className="col-12 text-right">
                    <StyledHelp>{translate("cart.wizard.contact-us")}</StyledHelp>
                  </div>

                </>
              )}
            />
          )}
          {showDataPreview && (
            <DataPreviewModal
              isOpen={showDataPreview}
              onClose={() => setShowDataPreview(false)}
              formData={formData}
              templateName={templateName}
              setTemplateName={setTemplateName}
              isSaveButtonEnabled={isSaveButtonEnabled}
              handleCheckboxChangeAcceptTerms={handleCheckboxChangeAcceptTerms}
              isCheckboxChecked={isCheckboxChecked}
              handleSaveTemplate={handleSaveTemplate}
              isSaving={isSaving}
              domain={selectedProduct ? selectedProduct.domain : ""}
            />
          )}

          {showDomainRequirementsModal && (
            <DomainRequirementsModal
              isOpen={showDomainRequirementsModal}
              onClose={() => setShowDomainRequirementsModal(false)}
              domain={selectedProduct ? selectedProduct.domain : ""}
            />
          )}
          {isLoadingTemplates && (
            <FullscreenLoader>
              <Spinner />
              <LoaderText>{translate("cart.wizard.account-info-modal.loading-text")}</LoaderText>
            </FullscreenLoader>
          )}
          {showMissingTekovnaModal && (
            <MissingTekovnaModal
              isOpen={showMissingTekovnaModal}
              onClose={() => setShowMissingTekovnaModal(false)}
              onSave={handleSaveTekovna}
              translate={translate}
              isSubmitting={isSubmitting}
              domain={selectedProduct && selectedProduct.domain}
              templateName={templateName}
              taxNumber={missingTekovnaTaxNumber}
              uploadDocumentSignedRequest={uploadDocumentSignedRequest}
              cartId={getCartId()}
              templateId={selectedTemplateId}
            />
          )}
          {showDnsModal && (
            <DnsSettingModal
              isOpen={showDnsModal}
              onClose={() => setShowDnsModal(false)}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              hosts={hosts}
              updateHost={updateHost}
              addHost={addHost}
              setHosts={setHosts}
              removeHost={removeHost}
              maxHosts={maxHosts}
              domainName={selectedProduct.domain}
              domain={selectedProduct.domain}
              onSaveDns={handleDnsSubmit}
              translate={translate}
              doesHostnameMatchDomain={doesHostnameMatchDomain}
              validateDnsSettings={validateDnsSettings}
              defaultDns={dnsSettingsByDomain[selectedProduct.domain]}
            />
          )}
        </div>
      )
      }
    </Translate >
  );
};

export default withRouter(withLocalize(AccountInfo));
