import React, { useState, useEffect, useRef } from 'react';
import Breadcrumb from '../../../components/Breadcrumb';
import BreadcrumbItem from '../../../components/Breadcrumb/BreadcrumbItem';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import { Translate, withLocalize } from 'react-localize-redux';
import en from "../../../translations/en.json";
import mk from "../../../translations/mk.json";
import Table from '../../../components/Table';
import TableHeader from '../../../components/Table/TableHeader';
import TableRow from '../../../components/Table/TableRow';
import TableHeading from '../../../components/Table/TableHeading';
import TableBody from '../../../components/Table/TableBody';
import TableCell from '../../../components/Table/TableCell';
import Pagination from '../../../components/Pagination';
import { createNewDomainTemplate, deleteTemplateById, getListDomainTemplates, updateTemplateById } from '../../../services/customerService';
import SecondaryButton from '../../../components/Buttons/SecondaryButton';
import { CONTACT_DETAILS_TYPE_COMPANY, CONTACT_DETAILS_TYPE_INDIVIDUAL } from '../ViewDomain/DomainContacts/ContactDetailsForm/ContactDetailsTypes';
import iconPersonActive from "../../../assets/images/icon-person-active.svg";
import iconBusinessActive from "../../../assets/images/icon-business-active.svg";
import { theme } from '../../../design/Themes';
import { withRouter } from 'react-router-dom';
import { ROUTE_DOMAINS } from '../../../routes/routes';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import CalloutBox from '../../../components/CalloutBox/CalloutBox';
import { te } from 'date-fns/locale';
import ManageDomainContact from '../../Checkout/Products/Domain/ManageDomainContact';
import DataPreviewModal from '../../Cart/DataPreviewModal';
import { normalizeMacedonianPhoneNumber } from '../../../common/validationRules';
import { getApplicationLocale } from '../../../services/localizationService';
import displayToastMessageForResponse from "../../../utils/displayToastMessageForResponse";
import BottomBulkActionBar from '../../../components/BottomBulkActionBar/BottomBulkActionBar';

//Lang
const lang = {
    en: en,
    mk: mk
};

const resolvePath = (object, path, defaultValue) =>
    path.split(".").reduce((o, p) => (o ? o[p] : defaultValue), object);

const translate = key =>
    resolvePath(lang[getApplicationLocale()], key, undefined);


const StyledDomainsWrapper = styled.div`margin-bottom: 10px;`;
const Input = styled.input`
  padding: 8px;
  width: 100%;
  max-width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;
const DropdownMenu = styled.div`
  padding: 15px 20px;
  width: 300px;
  position: absolute;
  right: 50px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: ${({ open }) => (open ? 'block' : 'none')};
  z-index: 10;
`;

const DropdownItem = styled.div`
  padding: 5px 0;
  cursor: pointer;
  margin-bottom: 5px;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledIconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 8px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  /* Enable horizontal scroll for small devices */
  overflow-x: auto;
  max-width: 100%;
  @media (max-width: 768px) {
    /* If needed, override some padding or margin */
    padding: 10px;
  }
`;

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
`;

const SearchAndActionButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-grow: 1; 
`;

const SearchInput = styled(Input)`
  flex-grow: 1; 
  margin-right: 10px; 
`;

const ActionButton = styled(SecondaryButton)`
  white-space: nowrap;
`;

const AddTemplateButton = styled(SecondaryButton)`
  padding: 8px 12px;
  font-size: 14px;
`;
const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  margin-top: 10px;
`;
const TabItem = styled.div`
  padding: 10px 15px;
  font-size: 14px;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  color: ${({ active }) => (active ? theme.neutralBase : theme.neutral6)};
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    display: ${({ active }) => (active ? 'block' : 'none')};
    height: 3px;
    width: 100%;
    background: ${theme.neutralBase};
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;
const StyledBreadcrumb = styled(Breadcrumb)`margin-bottom: 0px; padding-bottom: 0px;`;

const StatusBadge = styled.div`
  display: inline-block;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 600;
  background-color: ${({ bgColor }) => bgColor || "#E5E7EB"};
  color: ${({ textColor }) => textColor || "#374151"};
  text-align: center;
  min-width: 120px;
`;

const PaginationWrapper = styled.div`
  margin-top: 20px;
  /* On smaller screens, make sure it stays visible or pinned */
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const PageWrapper = styled.div`
    ${isMobileOnly ? "padding-bottom: 120px;" : ""}
`;

const STATUS_MAP = {
    active: { label: "Активен", color: "#D4F1DC", textColor: "#15803D" },
    pending: { label: "Се чека одобрување", color: "#FDF6C2", textColor: "#A16207" },
    grace_period: { label: "Грејс период за обнова", color: "#FDF6C2", textColor: "#A16207" },
    rejected: { label: "Одбиен", color: "#FECACA", textColor: "#B91C1C" },
    canceled: { label: "Откажан", color: "#FFE4B5", textColor: "#B45309" },
    signing_error: { label: "Грешка во потпишување", color: "#FECACA", textColor: "#B91C1C" },
    status_fix_needed: { label: "Потребна е корекција на шаблон", color: "#FEF3C7", textColor: "#92400E" },
    renewal_needed: { label: "Потребна е обнова", color: "#FEF9C3", textColor: "#92400E" }
};

const DomainTemplates = (props) => {
    const [domainTemplates, setDomainTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [menuOpen, setMenuOpen] = useState(null);
    const [activeTab, setActiveTab] = useState('templates');
    const [selectedIds, setSelectedIds] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [paging, setPaging] = useState({
        total_pages: 1,
        total: 0,
    });
    const [showAddInfoModal, setShowAddInfoModal] = useState(false);
    const [showEditInfoModal, setShowEditInfoModal] = useState(false);
    const [showCopyInfoModal, setShowCopyInfoModal] = useState(false);
    const [showDataPreview, setShowDataPreview] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [templateName, setTemplateName] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const isSaveButtonEnabled = templateName !== "" && isCheckboxChecked;
    const [editingTemplateId, setEditingTemplateId] = useState(null);
    const [originalTemplateData, setOriginalTemplateData] = useState(null);
    const dropdownRef = useRef(null);
    const [sortBy, setSortBy] = useState("created_at");
    const [orderBy, setOrderBy] = useState("asc");

    const [formData, setFormData] = useState({
        template_name: "",
        option1: {
            contact_type: "",
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            company: "",
            tax_number: "",
            street_number: "",
            address: "",
            city: "",
            zip: "",
            country: {
                id: "",
                name: ""
            }
        },
        option2: {
            administrative_contact: {
                name: "",
                surname: "",
                email: "",
                phone: "",
                country: {
                    id: "",
                    name: ""
                }
            },
            technical_contact: {
                name: "",
                surname: "",
                email: "",
                phone: "",
                country: {
                    id: "",
                    name: ""
                }
            },
        },
        option4: {
            registration_certificate: "",
            registration_certificate_date: "",
            order_registration_certificate: false,
        }
    });

    const resetFormFields = () => {
        setFormData({
            option1: {
                contact_type: CONTACT_DETAILS_TYPE_INDIVIDUAL, // Default to Individual
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                company: "",
                tax_number: "",
                street_number: "",
                address: "",
                city: "",
                zip: "",
                country: {
                    value: "",
                    label: ""
                }
            },
            option2: {
                administrative_contact: {
                    name: "",
                    surname: "",
                    email: "",
                    phone: "",
                    country: {
                        value: "",
                        label: ""
                    }
                },
                technical_contact: {
                    name: "",
                    surname: "",
                    email: "",
                    phone: "",
                    country: {
                        value: "",
                        label: ""
                    }
                },
            },
            option3: {
                selected_dns_option: "",
                dns_servers: [],
            },
            option4: {
                registration_certificate: "",
                registration_certificate_date: "",
                order_registration_certificate: false,
            },
        });

        setTemplateName("");
        setActiveTab("option1");
    };
    const resetModalState = () => {
        resetFormFields();
        setTemplateName("");
        setEditingTemplateId(null);
        setOriginalTemplateData(null);
        setIsCheckboxChecked(false);
    };

    useEffect(() => {
        fetchDomainTemplates();
    }, [currentPage, limit, sortBy, orderBy]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setMenuOpen(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    const handleCheckboxChangeAcceptTerms = () => {
        setIsCheckboxChecked(prev => !prev);
    };

    const getChangedFieldsOnly = (original, updated) => {
        const isObject = (val) => val && typeof val === "object" && !Array.isArray(val);
        const result = {};

        for (const key in updated) {
            if (isObject(updated[key])) {
                const nested = getChangedFieldsOnly(original[key] || {}, updated[key]);
                if (Object.keys(nested).length > 0) {
                    result[key] = nested;
                }
            } else if (!original || updated[key] !== original[key]) {
                result[key] = updated[key];
            }
        }

        return result;
    };

    const generateDuplicateTemplateName = (name, existingTemplates) => {
        const baseName = name.replace(/ Copy$/, "");

        let newName = `${baseName} Copy`;
        let counter = 2;

        // Avoid duplicates like "Template Copy", "Template Copy 2", etc.
        while (existingTemplates.some(t => t.template === newName)) {
            newName = `${baseName} Copy ${counter}`;
            counter++;
        }

        return newName;
    };

    const fetchDomainTemplates = async () => {
        console.log('Fetching page:', currentPage, 'limit:', limit);
        setIsLoading(true);
        try {
            const response = await getListDomainTemplates({
                page: currentPage,
                limit,
                sort_by: sortBy,
                order_by: orderBy,
            });

            if (response.data && response.data.data) {
                const { templates, paging: serverPaging } = response.data.data;

                setDomainTemplates(templates);

                // only set metadata, do NOT overwrite currentPage
                setPaging({
                    total_pages: serverPaging.total_pages,
                    total: serverPaging.total,
                    next_page: serverPaging.next_page,
                    previous_page: serverPaging.previous_page,
                });
            } else {
                setDomainTemplates([]);
                setPaging({ total_pages: 1, total: 0 });
            }
        } catch (error) {
            console.error("Error fetching templates", error);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteTemplate = async (id) => {
        try {
            const response = await deleteTemplateById(id);
            displayToastMessageForResponse(translate("cart.wizard.account-info-modal.validation.template-deleted-successfully"), {
                error: response.data.error,
                messages: ""
            });
            fetchDomainTemplates();
        } catch (error) {
            console.error("Error deleting template", error);
            console.log(error.message)

            displayToastMessageForResponse(
                translate("cart.wizard.account-info-modal.validation.template-deleted-unsuccesfully"),
                { error: error, messages: [error.response.data.data.messages] }
            );
        }
    };

    const cleanPhoneNumber = (phone) => {
        return phone ? phone.replace(/\./g, "") : "";
    };

    const formatCountry = (country) => {
        if (!country) return { value: "", label: "" };

        if (typeof country === "string") {
            return { value: country, label: country };
        }

        if (typeof country === "object") {
            return {
                value: country.value || country.id || "",
                label: country.label || country.name || "",
            };
        }

        return { value: "", label: "" };
    };

    const mapTemplateToFormData = (template) => {
        return {
            template_name: template.template || "",
            option1: {
                contact_type: template.type || "individual",
                first_name: template.registrant.first_name || "",
                last_name: template.registrant.last_name || "",
                email: template.registrant.email || "",
                phone: cleanPhoneNumber(template.registrant.phone_number) || "",
                company: template.registrant.company_name || "",
                tax_number: template.registrant.taxNumber || "",
                street_number: template.registrant.street_number || "",
                address: template.registrant.street || "",
                city: template.registrant.city || "",
                zip: template.registrant.postal_code || "",
                country: formatCountry(template.registrant.country),
            },
            option2: {
                administrative_contact: {
                    name: template.administrative_contact.first_name || "",
                    surname: template.administrative_contact.last_name || "",
                    email: template.administrative_contact.email || "",
                    phone: cleanPhoneNumber(template.administrative_contact.phone_number) || "",
                    country: formatCountry(template.administrative_contact.country),
                },
                technical_contact: {
                    name: template.technical_contact.first_name || "",
                    surname: template.technical_contact.last_name || "",
                    email: template.technical_contact.email || "",
                    phone: cleanPhoneNumber(template.technical_contact.phone_number) || "",
                    country: formatCountry(template.technical_contact.country),
                },
            },
            // option3: {
            //     selected_dns_option: "",
            //     dns_servers: [], // You'll add this later if needed
            // },
            option4: {
                registration_certificate: "",
                registration_certificate_date: "",
                order_registration_certificate: false,
            }
        };
    };

    const handlePaginationClick = (pageOrEvent) => {
        let page = null;

        if (typeof pageOrEvent === "number" && Number.isInteger(pageOrEvent)) {
            page = pageOrEvent;
        }

        if (!page && pageOrEvent && typeof pageOrEvent === "object") {
            const clickedEl = pageOrEvent.target;
            const className = clickedEl && clickedEl.className;

            if (className && className.indexOf("chevron_right") !== -1) {
                page = paging && paging.next_page;
            } else if (className && className.indexOf("chevron_left") !== -1) {
                page = paging && paging.previous_page;
            } else {
                const maybePage = parseInt(clickedEl && clickedEl.innerText, 10);
                if (!isNaN(maybePage)) {
                    page = maybePage;
                }
            }
        }

        if (Number.isInteger(page) && page > 0 && page !== currentPage) {
            setCurrentPage(page);
        }
    };

    const handlePerPageChange = ({ value }) => {
        console.log("Changed limit:", value);
        setLimit(value);
        setCurrentPage(1); // Reset to page 1
    };


    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaging(prev => ({
            ...prev,
            current_page: 1
        }));
    };

    const toggleMenu = (id) => {
        setMenuOpen(menuOpen === id ? null : id);
    };

    const handleCheckboxChange = (id) => {
        setSelectedIds((prev) =>
            prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
        );
    };

    const handleSelectAll = () => {
        const allIds = filteredTemplates.map((t) => t.id);
        const isAllSelected = allIds.every((id) => selectedIds.includes(id));
        setSelectedIds(isAllSelected ? [] : allIds);
    };

    const handleResign = (id) => {
        console.log("Resign clicked for:", id);
    };

    const handleFixTemplate = (id) => {
        console.log("Fix Template clicked for:", id);
    };

    const handleRenew = (id) => {
        console.log("Renew clicked for:", id);
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB');
    };

    const filteredTemplates = domainTemplates.filter((template) => {
        const name = template.template ? template.template.toLowerCase() : '';
        var registrant =
            template.registrant && template.registrant.country
                ? template.registrant.country.name.toLowerCase()
                : '';
        const query = searchQuery.toLowerCase();

        return (
            !searchQuery || name.includes(query) || registrant.includes(query)
        );
    });

    const handleSaveTemplate = async () => {

        const normalizedRegistrantPhone = normalizeMacedonianPhoneNumber(formData.option1.phone.trim());
        const normalizedAdminPhone = normalizeMacedonianPhoneNumber(formData.option2.administrative_contact.phone.trim());
        const normalizedTechPhone = normalizeMacedonianPhoneNumber(formData.option2.technical_contact.phone.trim());


        const payload = {
            template_name: templateName,
            registrant_first_name: formData.option1.first_name.trim() || "",
            registrant_last_name: formData.option1.last_name.trim() || "",
            registrant_company: formData.option1.company.trim() || "",
            registrant_street: formData.option1.address.trim() || "",
            registrant_street_number: formData.option1.street_number.trim(),
            registrant_postal_code: formData.option1.zip.trim() || "",
            registrant_city: formData.option1.city.trim() || "",
            registrant_country: formData.option1.country.value || "",
            registrant_tax_number: formData.option1.tax_number.trim() || "",
            registrant_phone_number: normalizedRegistrantPhone || "",
            registrant_email: formData.option1.email.trim() || "",
            administrative_contact_first_name: formData.option2.administrative_contact.name.trim() || "",
            administrative_contact_last_name: formData.option2.administrative_contact.surname.trim() || "",
            administrative_contact_email: formData.option2.administrative_contact.email.trim() || "",
            administrative_contact_phone_number: normalizedAdminPhone || "",
            administrative_contact_country: formData.option2.administrative_contact.country.value || "",
            technical_contact_first_name: formData.option2.technical_contact.name.trim() || "",
            technical_contact_last_name: formData.option2.technical_contact.surname.trim() || "",
            technical_contact_email: formData.option2.technical_contact.email.trim() || "",
            technical_contact_phone_number: normalizedTechPhone || "",
            technical_contact_country: formData.option2.technical_contact.country.value || "",
            registration_certificate: formData.option4.registration_certificate || "",
            registration_certificate_date: formData.option4.registration_certificate_date || null,
            order_registration_certificate: formData.option4.order_registration_certificate || false,
            type: formData.option1.contact_type || "individual",
        };

        try {
            setIsSaving(true);
            const response = await createNewDomainTemplate(payload);

            if (response.data) {
                displayToastMessageForResponse(translate("cart.wizard.account-info-modal.validation.template-saved-successfully"), {
                    error: response.data.error,
                    messages: ""
                });
                setShowDataPreview(false);
                setShowAddInfoModal(false);
                setShowPreviewModal(false);
                setShowCopyInfoModal(false);
                setShowEditInfoModal(false);
                resetModalState();
                setTemplateName("");
            } else {
                displayToastMessageForResponse(translate("cart.wizard.account-info-modal.validation.error-unexpected-occured"), {
                    error: response.data.error,
                    messages: ""
                });
            }
        } catch (error) {
            console.error("Error saving template:", error.message);
            displayToastMessageForResponse(translate("cart.wizard.account-info-modal.validation.error-unexpected-occured"), {
                error: error,
                messages: [error.response.data.data.messages]
            });
        } finally {
            setIsSaving(false);
        }
    };

    const handleUpdateTemplate = async () => {
        if (!editingTemplateId) return;

        const normalizedRegistrantPhone = normalizeMacedonianPhoneNumber(formData.option1.phone.trim());
        const normalizedAdminPhone = normalizeMacedonianPhoneNumber(formData.option2.administrative_contact.phone.trim());
        const normalizedTechPhone = normalizeMacedonianPhoneNumber(formData.option2.technical_contact.phone.trim());

        const payload = {
            template_name: templateName,
            registrant_first_name: formData.option1.first_name.trim(),
            registrant_last_name: formData.option1.last_name.trim(),
            registrant_company: formData.option1.company.trim(),
            registrant_street: formData.option1.address.trim(),
            registrant_street_number: formData.option1.street_number.trim(),
            registrant_postal_code: formData.option1.zip.trim(),
            registrant_city: formData.option1.city.trim(),
            registrant_country: formData.option1.country.value,
            registrant_tax_number: formData.option1.tax_number.trim(),
            registrant_phone_number: normalizedRegistrantPhone,
            registrant_email: formData.option1.email.trim(),
            administrative_contact_first_name: formData.option2.administrative_contact.name.trim(),
            administrative_contact_last_name: formData.option2.administrative_contact.surname.trim(),
            administrative_contact_email: formData.option2.administrative_contact.email.trim(),
            administrative_contact_phone_number: normalizedAdminPhone,
            administrative_contact_country: formData.option2.administrative_contact.country.value,
            technical_contact_first_name: formData.option2.technical_contact.name.trim(),
            technical_contact_last_name: formData.option2.technical_contact.surname.trim(),
            technical_contact_email: formData.option2.technical_contact.email.trim(),
            technical_contact_phone_number: normalizedTechPhone,
            technical_contact_country: formData.option2.technical_contact.country.value,
            registration_certificate: formData.option4.registration_certificate || "",
            registration_certificate_date: formData.option4.registration_certificate_date || null,
            order_registration_certificate: formData.option4.order_registration_certificate || false,
            type: formData.option1.contact_type || "individual",
        };

        // Now only keep changed fields
        const changedPayload = getChangedFieldsOnly(originalTemplateData, payload);
        changedPayload.template_name = templateName;

        try {
            setIsSaving(true);
            const response = await updateTemplateById(editingTemplateId, changedPayload);
            displayToastMessageForResponse(translate("cart.wizard.account-info-modal.validation.template-saved-successfully"), {
                error: response.data.error,
                messages: ""
            });
            setShowDataPreview(false);
            setShowEditInfoModal(false);
            setShowAddInfoModal(false);
            setShowPreviewModal(false);
            setShowCopyInfoModal(false);
            resetModalState();
            setEditingTemplateId(null);
            fetchDomainTemplates();
        } catch (error) {
            console.error("Error updating template:", error.message);
            displayToastMessageForResponse(translate("cart.wizard.account-info-modal.validation.error-unexpected-occured"), {
                error: error,
                messages: [error.response.data.data.messages]
            });
        } finally {
            setIsSaving(false);
        }
    };


    return (
        <Translate>
            {({ translate }) => (
                <div className='row'>
                    <PageWrapper className='col-sm-12 pr-0 pl-0'>
                        <div className='col-sm-12'>
                            <StyledDomainsWrapper isMobile={isMobileOnly} className="row">
                                <div className="col-sm-12 p-0 m-0">
                                    <StyledBreadcrumb className="pl-3">
                                        <BreadcrumbItem to="/" title={translate("breadcrumbs.home")} />
                                        <BreadcrumbItem to="/domains" title={translate("breadcrumbs.domains")} />
                                        <BreadcrumbItem title={translate("breadcrumbs.domains-templates")} />
                                    </StyledBreadcrumb>
                                    <TabsContainer>
                                        <TabItem active={activeTab === 'domains'} onClick={() => { setActiveTab('domains'); props.history.push(ROUTE_DOMAINS); }}>
                                            {translate("domain-templates.domains")}
                                        </TabItem>
                                        <TabItem active={activeTab === 'templates'} onClick={() => setActiveTab('templates')}>
                                            {translate("domain-templates.templates-for-registration")}
                                        </TabItem>
                                    </TabsContainer>
                                    {/* TODO: Treba diniamicki da se povikuvaat  */}
                                    {/* <CalloutBox
                                type="info"
                                bold="Потребна е корекција"
                                message=" на шаблон за регистрација за да продолжиш кон активирање на твоите домени!"
                                actionLabel="Коригирај шаблон"
                                onAction={() => {
                                    // You can route to fix page or open modal
                                    console.log("Clicked: Коригирај шаблон");
                                }}
                            />

                            <CalloutBox
                                type="info"
                                bold="Потребно е потпишување"
                                message=" на „Барање за регистрација“ за да ги активираме твоите домени!"
                                actionLabel="Потпиши барање"
                                onAction={() => {
                                    // Could open a modal or call an API
                                    console.log("Clicked: Потпиши барање");
                                }}
                            /> */}
                                </div>

                                <div className="col-sm-12">
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <TableContainer>
                                                <TableHeaderContainer className='row'>
                                                    <div className='col-sm-6'>
                                                        <SearchAndActionButtonContainer className={isMobileOnly ? 'row mb-2 ' : 'row'}>
                                                            <div className="col-sm-12">
                                                                <SearchInput
                                                                    type="text"
                                                                    placeholder={translate("domain-templates.table.search-placeholder")}
                                                                    value={searchQuery}
                                                                    onChange={handleSearchChange}
                                                                    className={isMobileOnly ? 'w-100' : ''}
                                                                />
                                                            </div>
                                                            {/* <div className="col-sm-3">
                                                                <ActionButton onClick={() => console.log("Action logic here")}>
                                                                    {translate("domain-templates.table.button")}
                                                                </ActionButton>
                                                            </div> */}
                                                        </SearchAndActionButtonContainer>
                                                    </div>
                                                    <div className="col-sm-6 d-flex justify-content-end">
                                                        <AddTemplateButton onClick={() => { resetFormFields(); setShowAddInfoModal(true); }}>
                                                            {translate("domain-templates.table.add-new-template-button")}
                                                        </AddTemplateButton>
                                                    </div>
                                                </TableHeaderContainer>
                                                <Table>
                                                    <TableHeader>
                                                        <TableRow>
                                                            <TableHeading>
                                                                <input type="checkbox" onChange={handleSelectAll} />
                                                            </TableHeading>
                                                            <TableHeading>{translate("domain-templates.table.name-of-template")}</TableHeading>
                                                            <TableHeading
                                                                onClick={() => {
                                                                    setOrderBy((prev) => (prev === "asc" ? "desc" : "asc"));
                                                                    setSortBy("created_at");
                                                                }}
                                                                style={{ cursor: "pointer", userSelect: "none" }}
                                                            >
                                                                {translate("domain-templates.table.date-of-creation")}{" "}
                                                                {sortBy === "created_at" && (orderBy === "asc" ? "↑" : "↓")}
                                                            </TableHeading>
                                                            <TableHeading>{translate("domain-templates.table.status")}</TableHeading>
                                                            <TableHeading>{translate("domain-templates.table.action")}</TableHeading>
                                                            {/* <TableHeading></TableHeading> */}
                                                        </TableRow>
                                                    </TableHeader>
                                                    <TableBody>
                                                        {isLoading ? (
                                                            <TableRow><TableCell colSpan={6}>Loading...</TableCell></TableRow>
                                                        ) : filteredTemplates.length > 0 ? (
                                                            filteredTemplates.map((template) => (
                                                                <TableRow key={template.id}>
                                                                    <TableCell>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={selectedIds.includes(template.id)}
                                                                            onChange={() => handleCheckboxChange(template.id)}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <StyledIconWrapper style={{ marginRight: 10 }}>
                                                                            <img
                                                                                src={
                                                                                    template.type === CONTACT_DETAILS_TYPE_COMPANY
                                                                                        ? iconBusinessActive
                                                                                        : iconPersonActive
                                                                                }
                                                                                alt="type"
                                                                                width={18}
                                                                                height={18}
                                                                            />
                                                                        </StyledIconWrapper>

                                                                        <span
                                                                            onMouseDown={(e) => {
                                                                                const formDataFromTemplate = mapTemplateToFormData(template);
                                                                                setFormData(formDataFromTemplate);
                                                                                setTemplateName(template.template);
                                                                                setShowPreviewModal(true);
                                                                                setMenuOpen(null);
                                                                            }}
                                                                            style={{ cursor: "pointer" }}
                                                                            onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
                                                                            onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
                                                                        >
                                                                            {template.template}
                                                                        </span>


                                                                    </TableCell>

                                                                    <TableCell>
                                                                        {template.created_at && template.created_at.date ? formatDate(template.created_at.date) : 'N/A'}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <StatusBadge
                                                                            bgColor={STATUS_MAP[template.status] && STATUS_MAP[template.status].color}
                                                                            textColor={STATUS_MAP[template.status] && STATUS_MAP[template.status].textColor}
                                                                        >
                                                                            {STATUS_MAP[template.status] ? STATUS_MAP[template.status].label : "Неизвестен"}
                                                                        </StatusBadge>
                                                                    </TableCell>
                                                                    {/* <TableCell>
                                                        <SecondaryButton onClick={() => handleResign(template.id)}>Потпиши повторно</SecondaryButton>

                                                    </TableCell> */}
                                                                    <TableCell>
                                                                        {template.status === 'signing_error' && (
                                                                            <SecondaryButton onClick={() => handleResign(template.id)}>{translate("domain-templates.table.dropdown-menu.sign-again")}</SecondaryButton>
                                                                        )}
                                                                        {template.status === 'status_fix_needed' && (
                                                                            <SecondaryButton onClick={() => handleFixTemplate(template.id)}>{translate("domain-templates.table.dropdown-menu.edit-template")}</SecondaryButton>
                                                                        )}
                                                                        {template.status === 'renewal_needed' && (
                                                                            <SecondaryButton onClick={() => handleRenew(template.id)}>{translate("domain-templates.table.dropdown-menu.renew-template")}</SecondaryButton>
                                                                        )}
                                                                        {!['signing_error', 'status_fix_needed', 'renewal_needed'].includes(template.status) && (
                                                                            <>
                                                                                <SecondaryButton onClick={() => toggleMenu(template.id)}>...</SecondaryButton>
                                                                                <DropdownMenu ref={dropdownRef} open={menuOpen === template.id}>
                                                                                    <DropdownItem
                                                                                        onMouseDown={(e) => {
                                                                                            const formDataFromTemplate = mapTemplateToFormData(template);
                                                                                            setFormData(formDataFromTemplate);
                                                                                            setTemplateName(template.template);
                                                                                            setShowPreviewModal(true);
                                                                                            setMenuOpen(null);
                                                                                        }}
                                                                                    >
                                                                                        {translate("domain-templates.table.dropdown-menu.view-template")}
                                                                                    </DropdownItem>
                                                                                    <DropdownItem
                                                                                        onMouseDown={(e) => {
                                                                                            const formDataFromTemplate = mapTemplateToFormData(template);
                                                                                            setFormData(formDataFromTemplate);
                                                                                            setTemplateName(template.template);
                                                                                            setEditingTemplateId(template.id);
                                                                                            setOriginalTemplateData(formDataFromTemplate);
                                                                                            setShowEditInfoModal(true);
                                                                                            setMenuOpen(null);
                                                                                        }}
                                                                                    >
                                                                                        {translate("domain-templates.table.dropdown-menu.edit-template")}
                                                                                    </DropdownItem>
                                                                                    <DropdownItem
                                                                                        onMouseDown={(e) => {
                                                                                            const formDataFromTemplate = mapTemplateToFormData(template);
                                                                                            const newTemplateName = generateDuplicateTemplateName(template.template, domainTemplates);

                                                                                            setFormData(formDataFromTemplate);
                                                                                            setTemplateName(newTemplateName);
                                                                                            setEditingTemplateId(null);
                                                                                            setOriginalTemplateData(null);
                                                                                            setShowCopyInfoModal(true);
                                                                                        }}
                                                                                    >
                                                                                        {translate("domain-templates.table.dropdown-menu.copy-edit-save")}
                                                                                    </DropdownItem>
                                                                                    <DropdownItem onMouseDown={(e) => deleteTemplate(template.id)}>{translate("domain-templates.table.dropdown-menu.delete-template")}</DropdownItem>
                                                                                </DropdownMenu>
                                                                            </>
                                                                        )}
                                                                    </TableCell>

                                                                </TableRow>
                                                            ))
                                                        ) : (
                                                            <TableRow><TableCell colSpan={6}>{translate("domain-templates.table.no-templates-found")}</TableCell></TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                                <PaginationWrapper>
                                                    <Pagination
                                                        paging={{
                                                            ...paging,
                                                            current_page: currentPage,
                                                            limit: limit
                                                        }}
                                                        itemsName={translate("domain-templates.table.templates")}
                                                        handlePaginationClick={handlePaginationClick}
                                                        onPerPageOptionChanged={handlePerPageChange}
                                                    />
                                                </PaginationWrapper>
                                            </TableContainer>
                                        </div>
                                    </div>
                                </div>
                            </StyledDomainsWrapper>
                            {showAddInfoModal && (
                                <ManageDomainContact
                                    showContactInfoModal={showAddInfoModal}
                                    setShowContactInfoModal={setShowAddInfoModal}
                                    setShowDataPreview={setShowDataPreview}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            )}
                            {showEditInfoModal && (
                                <ManageDomainContact
                                    showContactInfoModal={showEditInfoModal}
                                    setShowContactInfoModal={setShowEditInfoModal}
                                    setShowDataPreview={setShowDataPreview}
                                    formData={formData}
                                    setFormData={setFormData}
                                    isEdit={showEditInfoModal}
                                />
                            )}
                            {showCopyInfoModal && (
                                <ManageDomainContact
                                    showContactInfoModal={showCopyInfoModal}
                                    setShowContactInfoModal={setShowCopyInfoModal}
                                    setShowDataPreview={setShowDataPreview}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            )}
                            {showDataPreview && (
                                <DataPreviewModal
                                    isOpen={showDataPreview}
                                    onClose={() => {
                                        setShowDataPreview(false);
                                        // Call the callback that was passed from ManageDomainContact
                                        if (typeof props.onDataPreviewClose === 'function') {
                                            props.onDataPreviewClose();
                                        }
                                    }}
                                    formData={formData}
                                    templateName={templateName}
                                    setTemplateName={setTemplateName}
                                    isSaveButtonEnabled={isSaveButtonEnabled}
                                    handleCheckboxChangeAcceptTerms={handleCheckboxChangeAcceptTerms}
                                    isCheckboxChecked={isCheckboxChecked}
                                    handleSaveTemplate={showEditInfoModal ? handleUpdateTemplate : handleSaveTemplate}
                                    isSaving={isSaving}
                                    isCopy={showCopyInfoModal}
                                    domain={""}
                                />
                            )}
                            {showPreviewModal && (
                                <DataPreviewModal
                                    isOpen={showDataPreview}
                                    onClose={() => { setShowDataPreview(false); setShowPreviewModal(false); setShowCopyInfoModal(false); }}
                                    formData={formData}
                                    templateName={templateName}
                                    setTemplateName={setTemplateName}
                                    isSaveButtonEnabled={isSaveButtonEnabled}
                                    handleCheckboxChangeAcceptTerms={handleCheckboxChangeAcceptTerms}
                                    isCheckboxChecked={isCheckboxChecked}
                                    handleSaveTemplate={showEditInfoModal ? handleUpdateTemplate : handleSaveTemplate}
                                    isSaving={isSaving}
                                    onlyPreview={showPreviewModal}
                                    domain={""}
                                />
                            )}
                            <BottomBulkActionBar
                                count={selectedIds.length}
                                onDeleteClick={() => {
                                    selectedIds.forEach(id => deleteTemplate(id));
                                    setSelectedIds([]);
                                }}
                                onClose={() => setSelectedIds([])}
                                translate={translate}
                            />

                        </div>
                    </PageWrapper>
                </div>
            )}
        </Translate>
    );
};

export default withRouter(withLocalize(DomainTemplates));
